import { FunctionComponent } from 'react';
import { Link, LinkProps } from 'react-router-dom';

const DropdownMenuLink: FunctionComponent<LinkProps> = ({
  children,
  ...otherProps
}) => {
  return (
    <Link
      className="block px-4 py-2 w-full text-left text-gray-800 hover:bg-gray-100"
      {...otherProps}
    >
      {children}
    </Link>
  );
};

export default DropdownMenuLink;
