import { FunctionComponent, useEffect, useState } from 'react';
import apiService from '../../api/apiService';
import { SurveyAnswer } from '../../utils/surveyData';
import Spinner from '../Spinner';

interface Props {
  uuid: string;
  questionId: string;
  answers: SurveyAnswer[];
  startAt: string;
  endAt: string;
}

type DataShape = {
  counts: {
    [key: number]: number;
  };
  percents: {
    [key: number]: number;
  };
};

const SurveyAnswersTable: FunctionComponent<Props> = ({
  uuid,
  questionId,
  startAt,
  endAt,
  answers,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [data, setData] = useState<DataShape>({
    counts: {},
    percents: {},
  });

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      setError(undefined);

      try {
        const { data } = await apiService.charts.getSurveyAnswers({
          uuid,
          chartId: `question_answer_table_${questionId}`,
          startAt,
          endAt,
        });

        setData(data.chartInfo.chartInfo);
      } catch (error) {
        setError(error.message);
      }

      setIsLoading(false);
    };
    loadData();
  }, [uuid, questionId, startAt, endAt]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-96">
        <Spinner />
      </div>
    );
  }

  if (error) {
    return <div className="flex items-center justify-center h-96">{error}</div>;
  }

  return (
    <table className="w-full whitespace-nowrap">
      <thead className="text-left bg-gray-50 border border-gray-200 text-gray-500 text-sm uppercase">
        <tr>
          <th style={{ width: 1 }} className="p-2 font-semibold pl-6">
            ID
          </th>
          <th className="p-2">Label</th>
          <th style={{ width: 1 }} className="p-2 font-semibold text-center">
            Count
          </th>
          <th
            style={{ width: 1 }}
            className="p-2 font-semibold pr-6 text-right"
          >
            Count %
          </th>
        </tr>
      </thead>
      <tbody>
        {answers.map((answer: any) => (
          <tr
            key={`answer-${answer.value}`}
            className="text-left border border-gray-200 hover:bg-gray-50"
          >
            <td className="text-center p-2 pl-6">{answer.value}</td>
            <td className="text-left p-2">{answer.label}</td>
            <td className="text-center p-2">{data.counts[answer.value]}</td>
            <td className="text-right p-2 pr-6">
              {(data.percents[answer.value] * 100).toFixed(1)}%
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default SurveyAnswersTable;
