import cx from 'classnames';
import { FunctionComponent, ReactNode } from 'react';

interface Props {
  color: 'green' | 'red';
  ratio: number;
  barLabel: ReactNode;
}

const PercentageBar: FunctionComponent<Props> = ({
  color,
  ratio,
  barLabel,
}) => {
  const barWidth = `${ratio * 100}%`;
  const showLabelOnTheRight = ratio < 0.05;

  return (
    <div
      className={cx('rounded flex items-center', {
        'bg-green-100': color === 'green',
        'bg-red-100': color === 'red',
      })}
    >
      <div
        className={cx(
          'h-8 rounded flex items-center justify-center text-white font-semibold',
          {
            'bg-green-700': color === 'green',
            'bg-red-700': color === 'red',
          },
        )}
        style={{ width: barWidth }}
      >
        {!showLabelOnTheRight && <span>{barLabel}</span>}
      </div>
      {showLabelOnTheRight && (
        <span
          className={cx('font-semibold ml-3', {
            'text-green-800': color === 'green',
            'text-red-800': color === 'red',
          })}
        >
          {barLabel}
        </span>
      )}
    </div>
  );
};

export default PercentageBar;
