import { ResponsiveLine } from '@nivo/line';
import { DateTime } from 'luxon';
import { FunctionComponent, useEffect, useState } from 'react';
import apiService from '../../api/apiService';
import SpinnerSlate from '../SpinnerSlate';
import NivoTooltip from './NivoTooltip';

interface Props {
  uuid: string;
  startAt: string;
  endAt: string;
}

const AppSessionsOverTime: FunctionComponent<Props> = ({
  uuid,
  startAt,
  endAt,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [graphData, setGraphData] = useState<any>();

  useEffect(() => {
    setIsLoading(true);

    const loadData = async () => {
      const sessionsOverTime = await apiService.charts.getSessionsOverTime({
        uuid,
        startAt,
        endAt,
      });

      const lineData = Object.keys(sessionsOverTime).map((key) => ({
        x: key,
        y: sessionsOverTime[key],
      }));

      setGraphData([
        {
          id: 'stuff',
          data: lineData,
        },
      ]);
      setIsLoading(false);
    };

    loadData();
  }, [uuid, startAt, endAt]);

  return (
    <div className="text-center text-gray-700" style={{ height: 300 }}>
      <h3 className="font-semibold text-gray-700 mb-4">Sessions over time</h3>
      {isLoading && <SpinnerSlate style={{ height: 200 }} />}
      {!isLoading && graphData.length && (
        <ResponsiveLine
          data={graphData}
          theme={{ fontFamily: 'Inter, Arial, sans-serif' }}
          margin={{ top: 10, right: 30, bottom: 90, left: 60 }}
          curve="monotoneX"
          xScale={{
            type: 'time',
            format: '%Y-%m-%d',
            useUTC: false,
            precision: 'day',
          }}
          xFormat="time:%Y-%m-%d"
          yScale={{
            type: 'linear',
          }}
          axisLeft={{
            legend: 'Sessions',
            legendPosition: 'middle',
            legendOffset: -48,
          }}
          axisBottom={{
            format: '%b %d',
            tickValues: 8,
            legend: 'Time',
            legendPosition: 'middle',
            legendOffset: 40,
          }}
          colors="#3B82F6"
          enablePoints={false}
          enableSlices="x"
          sliceTooltip={({ slice }) => (
            <NivoTooltip>
              <div>
                {DateTime.fromISO(slice.points[0].data
                  .xFormatted as string).toLocaleString(DateTime.DATE_MED)}
              </div>
              {slice.points.map((point) => (
                <div
                  key={point.id}
                  style={{
                    color: point.serieColor,
                    padding: '3px 0',
                  }}
                >
                  <span>{point.data.yFormatted} sessions</span>
                </div>
              ))}
            </NivoTooltip>
          )}
        />
      )}
    </div>
  );
};

export default AppSessionsOverTime;
