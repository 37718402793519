import create from 'zustand';
import { TAppInfo } from '../types/backend';

type UserProfile = {
  isAuthenticated: boolean;
  apps: TAppInfo[];
  name?: string;
  role?: string;
};

const useUserProfile = create<UserProfile>(() => ({
  isAuthenticated: false,
  apps: [],
}));

export default useUserProfile;
