import { FormEvent, FunctionComponent, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import apiService from '../../api/apiService';
import AppLayout from '../../AppLayout';
import Button from '../../components/Button';
import AppSettingsLayout from './AppSettingsLayout';

const UserPasswordSettingsPage: FunctionComponent = (props) => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPassword2, setNewPassword2] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>();
  const history = useHistory();

  const onChangeCurrentPassword = (ev: any) => {
    setCurrentPassword(ev.target.value);
  };

  const onChangeNewPassword = (ev: any) => {
    setNewPassword(ev.target.value);
  };

  const onChangeNewPassword2 = (ev: any) => {
    setNewPassword2(ev.target.value);
  };

  const onSubmitPassword = (event: FormEvent) => {
    event.preventDefault();
    setError(undefined);

    if (newPassword !== newPassword2) {
      setError('New passwords must match');
      return;
    }

    if (newPassword.length < 6) {
      setError('New password must be at least 6 characters long');
      return;
    }

    if (!currentPassword) {
      setError('Missing current password');
      return;
    }

    const saveParams = {
      oldPassword: currentPassword,
      newPassword: newPassword,
    };

    setIsLoading(true);

    apiService.admin
      .saveUserPasswordSettings(saveParams)
      .then(() => {
        history.push('/settings');
      })
      .catch((error) => {
        setError(error.toString());
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <AppLayout>
      <AppSettingsLayout>
        <Helmet>
          <title>Password Settings</title>
        </Helmet>
        <form className="text-gray-800" onSubmit={onSubmitPassword}>
          <h1 className="text-3xl mb-8">Change Password</h1>
          {error && <div className="bg-red-100 text-red-700 p-4">{error}</div>}
          <div className="flex">
            <div className="w-3/5">
              <div className="space-y-6">
                <div>
                  <label className="label" htmlFor="current">
                    Current Password:
                  </label>
                  <input
                    onChange={onChangeCurrentPassword}
                    type="password"
                    id="current"
                    className="textfield"
                    autoComplete="current-password"
                    placeholder=""
                    disabled={isLoading}
                  />
                </div>
                <div>
                  <label className="label" htmlFor="new">
                    New Password:
                  </label>
                  <input
                    onChange={onChangeNewPassword}
                    type="password"
                    id="new"
                    className="textfield"
                    autoComplete="new-password"
                    placeholder="Must be at least 6 characters"
                    disabled={isLoading}
                  />
                </div>
                <div>
                  <label className="label" htmlFor="new2">
                    Verify New Password:
                  </label>
                  <input
                    onChange={onChangeNewPassword2}
                    type="password"
                    id="new2"
                    className="textfield"
                    autoComplete="new-password"
                    placeholder="Must be at least 6 characters"
                    disabled={isLoading}
                  />
                </div>
              </div>

              <Button
                disabled={isLoading}
                variant="brand"
                className="mt-6"
                type="submit"
              >
                Change Password
              </Button>
            </div>
          </div>
        </form>
      </AppSettingsLayout>
    </AppLayout>
  );
};

export default UserPasswordSettingsPage;
