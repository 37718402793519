import { FunctionComponent, useEffect, useState } from 'react';
import apiService from '../../api/apiService';
import { formatMsReadable } from '../../utils/format';
import Spinner from '../Spinner';
import { DateTime } from 'luxon';

interface Props {
  uuid: string;
  questionId: string;
  startAt: string;
  endAt: string;
}

type DataShape = any[];

const STRESS_LEVELS_BY_VALUE = [
  `Not stressed at all`,
  `A Little Stressed`,
  `A Little Stressed`,
  `A Little Stressed`,
  `Stressed`,
  `Stressed`,
  `Stressed`,
  `Stressed`,
  `Severely Stressed`,
  `Severely Stressed`,
  `Severely Stressed`,
];

const WellnessResultsTable: FunctionComponent<Props> = ({
  uuid,
  questionId,
  startAt,
  endAt,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [data, setData] = useState<DataShape>([]);

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      setError(undefined);

      try {
        const { data } = await apiService.charts.getSurveyAnswers({
          uuid,
          chartId: `wellness_results_table_${questionId}`,
          startAt,
          endAt,
        });

        setData(data.chartInfo);
      } catch (error) {
        setError(error.message);
      }

      setIsLoading(false);
    };
    loadData();
  }, [uuid, questionId, startAt, endAt]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-96">
        <Spinner />
      </div>
    );
  }

  if (error) {
    return <div className="flex items-center justify-center h-96">{error}</div>;
  }

  return (
    <table className="w-full whitespace-nowrap">
      <thead className="text-left bg-gray-50 border border-gray-200 text-gray-500 text-sm uppercase">
        <tr>
          <th style={{ width: 1 }} className="p-2 font-semibold pl-6">
            Device ID
          </th>
          <th style={{ width: 1 }} className="p-2 font-semibold text-center">
            Launched On
          </th>
          <th className="p-2 font-semibold text-center">Stress Before</th>
          <th style={{ width: 1 }} className="p-2 font-semibold text-center">
            Stress After
          </th>
          <th className="p-2 font-semibold text-center">Stress Change</th>
          <th
            style={{ width: 1 }}
            className="p-2 font-semibold pr-6 text-right"
          >
            Duration
          </th>
        </tr>
      </thead>
      <tbody>
        {data.map((row: any) => (
          <tr
            key={`answer-${row.gameLaunchedAt}`}
            className="text-left border border-gray-200 hover:bg-gray-50"
          >
            <td className="text-center p-2 pl-6">{row.deviceId}</td>
            <td className="text-center p-2 pl-6">
              {DateTime.fromISO(row.gameLaunchedAt).toLocaleString(
                DateTime.DATETIME_MED,
              )}
            </td>
            <td className="text-center p-2">
              {STRESS_LEVELS_BY_VALUE[row.stressBefore]} ({row.stressBefore})
            </td>
            <td className="text-center p-2">
              {STRESS_LEVELS_BY_VALUE[row.stressAfter]} ({row.stressAfter})
            </td>
            <td className="text-center p-2">{row.stressDelta}</td>
            <td className="text-right p-2 pr-6">
              {formatMsReadable(1000 * row.gameDuration)}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default WellnessResultsTable;
