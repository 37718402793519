import cx from 'classnames';
import { FunctionComponent } from 'react';

const Spinner: FunctionComponent<{ className?: string }> = (props) => {
  return (
    <div className={cx('spinner', props.className)} role="status">
      <div className="rect1"></div>
      <div className="rect2"></div>
      <div className="rect3"></div>
      <div className="rect4"></div>
      <div className="rect5"></div>
    </div>
  );
};

export default Spinner;
