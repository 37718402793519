import { ResponsiveLine } from '@nivo/line';
import { FunctionComponent, useEffect, useState } from 'react';
import apiService from '../../api/apiService';
import NivoTooltip from '../../components/charts/NivoTooltip';
import PercentageBar from '../../components/charts/PercentageBar';
import SpinnerSlate from '../../components/SpinnerSlate';
import Tag from '../../components/Tag';
import { plural } from '../../utils/format';

interface Props {
  uuid: string;
  startAt: string;
  endAt: string;
  moduleName: string;
}

type RecordShape = {
  answers: {
    answer: string;
    timesSelected: number;
    isCorrect?: boolean;
  }[];
  isMultipleChoice: boolean;
  totalResponses: number;
  question: string;
  totalTimeSpent: number;
  timeIntervals: { value: number; id: string }[];
};

const ModuleQuizAnswers: FunctionComponent<Props> = ({
  uuid,
  startAt,
  endAt,
  moduleName,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [quizData, setQuizData] = useState<RecordShape[]>([]);

  useEffect(() => {
    if (uuid) {
      setIsLoading(true);

      apiService.charts
        .getModuleQuizResults({
          startAt,
          uuid,
          endAt,
          moduleName,
        })
        .then(({ data }) => {
          setQuizData(data);
          setIsLoading(false);
        });
    }
  }, [uuid, moduleName, startAt, endAt]);

  if (isLoading) {
    return <SpinnerSlate style={{ height: 200 }} />;
  }

  if (!quizData || quizData.length === 0) {
    return (
      <div
        style={{ height: 200 }}
        className="flex bg-gray-100 text-gray-600 items-center justify-center"
      >
        No quiz data available
      </div>
    );
  }

  return (
    <div className="space-y-12">
      {quizData.map((item) => (
        <div key={item.question} className="p-4 bg-white rounded shadow-lg">
          <h4 className="font-semibold text-gray-700 mb-4">
            {item.question}{' '}
            {item.isMultipleChoice && (
              <Tag color="blue" className="ml-4">
                Multiple Choice
              </Tag>
            )}
          </h4>

          <div className="md:flex md:space-x-8">
            <section className="w-full md:w-1/2">
              <h5 className="text-center font-medium text-gray-600 mb-4">
                {item.totalResponses} {plural('response', item.totalResponses)}
              </h5>
              {item.answers.map((answer, index) => (
                <div key={`answer-${index}`} className="mb-6 max-w-3xl">
                  <div className="flex justify-between mb-1 text-gray-700">
                    <span>
                      {answer.answer}
                      <Tag
                        color={answer.isCorrect ? 'green' : 'red'}
                        className="ml-4"
                      >
                        {answer.isCorrect ? 'Correct' : 'Incorrect'}
                      </Tag>
                    </span>
                    <span className="ml-4">
                      {(
                        (answer.timesSelected / item.totalResponses) *
                        100
                      ).toFixed()}
                      %
                    </span>
                  </div>
                  <PercentageBar
                    color={answer.isCorrect ? 'green' : 'red'}
                    ratio={answer.timesSelected / item.totalResponses}
                    barLabel={answer.timesSelected}
                  />
                </div>
              ))}
            </section>
            <section className="w-full md:w-1/2">
              <h5 className="text-center font-medium text-gray-600">
                Response time
              </h5>
              <p className="text-gray-700 text-sm text-center">
                Average: {Math.round(item.totalTimeSpent / item.totalResponses)}{' '}
                seconds
              </p>
              <div style={{ height: 200 }}>
                <ResponsiveLine
                  data={[{ id: 'test', data: item.timeIntervals }]}
                  theme={{ fontFamily: 'Inter, Arial, sans-serif' }}
                  margin={{ top: 20, right: 40, bottom: 40, left: 60 }}
                  colors="#7C3AED"
                  curve="monotoneX"
                  enableSlices="x"
                  xScale={{ type: 'linear' }}
                  axisBottom={{
                    tickValues: 12,
                    legend: 'time (seconds)',
                    legendPosition: 'middle',
                    legendOffset: 32,
                  }}
                  axisLeft={{
                    tickValues: 5,
                    legend: 'responses',
                    legendPosition: 'middle',
                    legendOffset: -40,
                    format: (value) => (value % 1 === 0 ? value : ''),
                  }}
                  sliceTooltip={({ slice }) => (
                    <NivoTooltip>
                      <div>{slice.points[0].data.xFormatted} seconds</div>
                      {slice.points.map((point) => (
                        <div
                          key={point.id}
                          style={{
                            color: point.serieColor,
                            padding: '3px 0',
                          }}
                        >
                          <span>
                            {point.data.yFormatted}{' '}
                            {plural('response', point.data.yFormatted)}
                          </span>
                        </div>
                      ))}
                    </NivoTooltip>
                  )}
                />
              </div>
            </section>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ModuleQuizAnswers;
