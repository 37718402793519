import _get from 'lodash/get';

export function formatAxiosError(axiosError: any, fallbackMessage: string) {
  return _get(axiosError, 'response.data.message', fallbackMessage);
}

export function plural(str: string, num: number | string) {
  if (typeof num === 'string') {
    num = parseInt(num, 10);
  }

  if (num === 1) {
    return str;
  }
  return `${str}s`;
}

export function getTimeParts(ms: number) {
  let seconds = Math.floor(ms / 1000);
  const days = Math.floor(seconds / (3600 * 24));
  seconds -= days * (3600 * 24);
  const hours = Math.floor(seconds / 3600);
  seconds -= hours * 3600;
  const minutes = Math.floor(seconds / 60);
  seconds -= minutes * 60;

  const fractionalSeconds = ms - seconds * 1000;

  return [days, hours, minutes, seconds, fractionalSeconds];
}

/**
 * Formats an amount of milliseconds into the following format:
 * "w days, x hours, y days, z minutes"
 */
export function formatMsReadable(ms: number) {
  const [days, hours, minutes, seconds] = getTimeParts(ms);
  const parts = [`${seconds} ${plural('second', seconds)}`];

  if (minutes > 0) {
    parts.unshift(`${minutes} ${plural('minute', minutes)}`);
  }

  if (hours > 0) {
    parts.unshift(`${hours} ${plural('hour', hours)}`);
  }

  if (days > 0) {
    parts.unshift(`${days} ${plural('day', days)}`);
  }

  return parts.join(', ');
}

export function formatRoundedBiggestUnit(ms: number) {
  const [days, hours, minutes, seconds, fractionalSeconds] = getTimeParts(ms);
  if (days > 0) {
    const roundedDays = Math.round(days + hours / 24);
    return `${roundedDays} ${plural('day', roundedDays)}`;
  } else if (hours > 0) {
    const roundedHours = Math.round(hours + minutes / 60);
    return `${roundedHours} ${plural('hour', roundedHours)}`;
  } else if (minutes > 0) {
    const roundedMinutes = Math.round(minutes + seconds / 60);
    return `${roundedMinutes} ${plural('minute', roundedMinutes)}`;
  } else {
    const roundedSeconds = Math.round(seconds + fractionalSeconds / 1.0);
    return `${roundedSeconds} ${plural('second', roundedSeconds)}`;
  }
}
