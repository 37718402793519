import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import AppLayout from '../AppLayout';

const NotFoundPage: FunctionComponent = () => (
  <AppLayout>
    <div>
      <div>
        <div className="w-full md:w-1/2 p-4 mx-auto">
          <h1 className="text-3xl mb-6">Page Not Found</h1>
          <p>
            Uh oh! It appears that the page you have requested does not exist.
          </p>
          <p className="mt-6">
            Go back to the <Link to="/">Home Page</Link>
          </p>
        </div>
      </div>
    </div>
  </AppLayout>
);

export default NotFoundPage;
