import { FunctionComponent, useEffect, useState } from 'react';
import apiService from '../../api/apiService';
import SpinnerSlate from '../SpinnerSlate';

interface Props {
  uuid: string;
  startAt: string;
  endAt: string;
}

const AppSessionCount: FunctionComponent<Props> = ({
  uuid,
  startAt,
  endAt,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [numSessions, setNumSessions] = useState(0);

  useEffect(() => {
    setIsLoading(true);
    const loadData = async () => {
      const sessionCount = await apiService.charts.getSessionCount({
        uuid,
        startAt,
        endAt,
      });
      setNumSessions(sessionCount);
      setIsLoading(false);
    };

    loadData();
  }, [uuid, startAt, endAt]);

  if (isLoading) {
    return <SpinnerSlate style={{ height: 300 }} />;
  }

  return (
    <div
      className="text-center text-gray-700 flex items-center justify-center"
      style={{ height: 300 }}
    >
      <div>
        <div className="text-7xl">{numSessions}</div>
        <div className="text-3xl">Total tracked sessions</div>
      </div>
    </div>
  );
};

export default AppSessionCount;
