import { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';
import apiService, { PermissionUpdate } from '../../api/apiService';
import AppLayout from '../../AppLayout';
import Alert from '../../components/Alert';
import Button from '../../components/Button';
import useUserProfile from '../../hooks/useUserProfile';
import {
  IAppUserPermissionAttributes,
  IUserAttributes,
  TPermission,
} from '../../types/backend';
import { formatAxiosError } from '../../utils/format';
import AdminSideNav from './AdminSideNav';

const PERMISSIONS: TPermission[] = ['view_dashboard', 'manage_users'];

const ManageUserPermissionsPage: FunctionComponent = () => {
  const { role: userRole, apps } = useUserProfile((state) => state);

  // Grab the user ID from the URL
  const { userId } = useParams<{ userId: string }>();

  // Store the user's data
  const [userData, setUserData] = useState<IUserAttributes>();
  const [permissionsLoaded, setPermissionsLoaded] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>();

  const [modifiedPermissions, setModifiedPermissions] = useState<
    PermissionUpdate[]
  >([]);

  const resetModifiedPermissions = (
    permissions: IAppUserPermissionAttributes[],
  ) => {
    setModifiedPermissions(
      permissions.map((perm) => ({
        appId: perm.app_id,
        permission: perm.permission,
        granted: true,
      })),
    );
  };

  useEffect(() => {
    setIsLoading(true);

    // Load data about the user
    const fetchData = async () => {
      await Promise.all([
        apiService.admin.getUser(userId),
        apiService.admin.getPermissionsForUser(userId),
      ])
        .then(([{ data: user }, permissions]) => {
          setUserData(user);
          resetModifiedPermissions(permissions);
          setPermissionsLoaded(true);
        })
        .catch((error) => {
          setError(
            formatAxiosError(
              error,
              'Unable to fetch data for this user. Please reload the page.',
            ),
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    fetchData();
  }, [userId]);

  const getPermissionForApp = (appId: number, permission: TPermission) => {
    const matchingPermission = modifiedPermissions.find(
      (perm) => perm.appId === appId && perm.permission === permission,
    );

    return !!matchingPermission && matchingPermission.granted;
  };

  /**
   * Persist the updated permissions into the database
   */
  const savePermissionChanges = () => {
    if (modifiedPermissions) {
      setIsLoading(true);

      apiService.admin
        .setAppUserPermissions(userId, modifiedPermissions)
        .then((updatedPermissions) => {
          resetModifiedPermissions(updatedPermissions);
        })
        .catch((error) => {
          setError(
            formatAxiosError(
              error,
              'Sorry, we were unable to save your changes. Please refresh the page and try again.',
            ),
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const togglePermissionForApp = (
    appId: number,
    permission: TPermission,
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const updatedPermissions = [...modifiedPermissions];
    const matchingPermission = updatedPermissions.find(
      (perm) => perm.appId === appId && perm.permission === permission,
    );

    if (matchingPermission) {
      matchingPermission.granted = event.currentTarget.checked;
    } else {
      updatedPermissions.push({
        appId,
        permission,
        granted: event.currentTarget.checked,
      });
    }

    setModifiedPermissions(updatedPermissions);
  };

  return (
    <AppLayout>
      <Helmet>
        <title>Permissions</title>
      </Helmet>
      <div>
        <div className="flex w-full max-w-5xl mx-auto py-12">
          <div className="w-2/5">
            <AdminSideNav />
          </div>
          <div className="w-4/5 p-4 md:p-6 ml-6 bg-white shadow rounded">
            <div>
              {error && <Alert>{error}</Alert>}

              <Link to="/admin/users" className="mb-6 block">
                ‹ Back to user list
              </Link>

              {userData && permissionsLoaded && (
                <div className="text-gray-800">
                  <h1 className="text-3xl mb-8">
                    Permissions for {userData.email}
                  </h1>

                  {userRole === 'superadmin' && (
                    <p className="mb-6 text-gray-700">
                      This user is a <strong>superadmin</strong> so changing
                      their app-level permissions will have no effect.
                    </p>
                  )}

                  <table className="w-full">
                    <thead>
                      <tr>
                        <th></th>
                        {PERMISSIONS.map((perm) => (
                          <td
                            className="p-2 text-gray-700 text-center"
                            key={perm}
                          >
                            {perm}
                          </td>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {apps.map((app) => (
                        <tr
                          key={app.uuid}
                          className="border-t border-gray-100 hover:bg-gray-100"
                        >
                          <td className="p-2 font-semibold text-gray-600">
                            {app.name}
                          </td>
                          {PERMISSIONS.map((perm) => (
                            <td className="p-2" key={`perm-value-${perm}`}>
                              <label className="flex items-center justify-center h-8 cursor-pointer">
                                <input
                                  type="checkbox"
                                  className="checkbox"
                                  name={`${app.id}-${perm}`}
                                  onChange={(e) =>
                                    togglePermissionForApp(app.id, perm, e)
                                  }
                                  checked={getPermissionForApp(app.id, perm)}
                                />
                              </label>
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <div className="mt-8">
                    <Button
                      variant="brand"
                      onClick={savePermissionChanges}
                      type="submit"
                      loading={isLoading}
                    >
                      Save Changes
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  );
};

export default ManageUserPermissionsPage;
