import cx from 'classnames';
import { FunctionComponent, HTMLAttributes } from 'react';
import Spinner from './Spinner';

const SpinnerSlate: FunctionComponent<HTMLAttributes<HTMLDivElement>> = ({
  className,
  ...otherProps
}) => {
  return (
    <div
      className={cx('flex items-center justify-center', className)}
      {...otherProps}
    >
      <Spinner />
    </div>
  );
};

export default SpinnerSlate;
