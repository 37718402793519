import { ResponsivePie } from '@nivo/pie';
import { FunctionComponent, useEffect, useState } from 'react';
import apiService from '../../api/apiService';
import SpinnerSlate from '../../components/SpinnerSlate';

interface Props {
  uuid: string;
  startAt: string;
  endAt: string;
  moduleName: string;
}

type RecordShape = {
  questionId: string;
  answers: { answer: string; timesSelected: number }[];
  totalAnswers: number;
};

const ModuleSurveyAnswers: FunctionComponent<Props> = ({
  uuid,
  moduleName,
  startAt,
  endAt,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [surveyData, setSurveyData] = useState<RecordShape[]>([]);

  useEffect(() => {
    if (uuid) {
      setIsLoading(true);

      apiService.charts
        .getModuleSurveyResults({
          startAt,
          uuid,
          endAt,
          moduleName,
        })
        .then(({ data }) => {
          setSurveyData(data);
          setIsLoading(false);
        });
    }
  }, [uuid, moduleName, startAt, endAt]);

  if (isLoading) {
    return <SpinnerSlate style={{ height: 200 }} />;
  }

  if (!surveyData || surveyData.length === 0) {
    return (
      <div
        style={{ height: 200 }}
        className="flex bg-gray-100 text-gray-600 items-center justify-center"
      >
        No survey data available
      </div>
    );
  }

  return (
    <div className="grid lg:grid-rows-2 lg:grid-flow-col gap-4">
      {surveyData.map((result) => (
        <div
          key={result.questionId}
          className="bg-white p-4 rounded-lg md:flex items-center shadow"
        >
          <div className="max-w-xl w-full">
            <h4 className="text-gray-800 mb-4">{result.questionId}</h4>
            <p className="text-gray-600">{result.totalAnswers} answers</p>
          </div>
          <div style={{ height: 200, width: 300 }}>
            <ResponsivePie
              data={result.answers.map((answer) => ({
                value: answer.timesSelected,
                id: answer.answer,
              }))}
              theme={{ fontFamily: 'Inter, Arial, sans-serif' }}
              colors={['#1D4ED8', '#A21CAF', '#7E22CE', '#4338CA', '#BE185D']}
              margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
              innerRadius={0.33}
              padAngle={2}
              cornerRadius={2}
              activeOuterRadiusOffset={8}
              borderWidth={1}
              borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
              arcLinkLabelsSkipAngle={10}
              arcLinkLabelsTextColor="#333333"
              arcLinkLabelsThickness={2}
              arcLinkLabelsColor={{ from: 'color' }}
              arcLabelsSkipAngle={10}
              arcLabelsTextColor="#fff"
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default ModuleSurveyAnswers;
