import { FunctionComponent } from 'react';
import starsBackgroungImg from '../images/backgrounds/star4.jpg';
import version from '../version.json';

const VERSION_STRING = `${version.major}.${version.minor}.${version.patch} (${version.tag})`;

const BACKGROUND_IMAGES = [
  // '/images/login/backgrounds/star1.jpg',
  // '/images/login/backgrounds/star2.jpg',
  // '/images/login/backgrounds/star3.jpg',
  starsBackgroungImg,
];

// picked once at load time, so it does not change between different pages
const sessionRandomImage =
  BACKGROUND_IMAGES[Math.floor(Math.random() * BACKGROUND_IMAGES.length)];

type TBlurredBackgroundContainerProps = {
  width: number;
};

const BlurredBackgroundContainer: FunctionComponent<
  TBlurredBackgroundContainerProps
> = (props) => {
  const backgroundImage = `url(${sessionRandomImage})`;

  return (
    <div
      className="relative min-h-screen bg-black bg-cover"
      style={{ backgroundImage }}
    >
      <div className="flex items-center justify-center z-20">
        <div className="md:mt-24" style={{ width: props.width }}>
          <div className="bg-white rounded-sm h-full">{props.children}</div>
        </div>
      </div>
      <div className="p-4 opacity-75 text-xs md:mb-24">
        <p className="text-center text-white">
          STARS&trade; &copy; 2016-2023 ForwardXP, Inc. &mdash; v
          {VERSION_STRING}
        </p>
      </div>
    </div>
  );
};

export default BlurredBackgroundContainer;
