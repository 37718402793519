import { ResponsiveLine } from '@nivo/line';
import { FunctionComponent, useEffect, useState } from 'react';
import apiService from '../../api/apiService';
import SpinnerSlate from '../SpinnerSlate';
import NivoTooltip from './NivoTooltip';

interface Props {
  uuid: string;
  startAt: string;
  endAt: string;
}

const AppSessionDurations: FunctionComponent<Props> = ({
  uuid,
  startAt,
  endAt,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [graphData, setGraphData] = useState<any[]>([]);

  useEffect(() => {
    setIsLoading(true);

    const loadData = async () => {
      const sessionsOverTime = await apiService.charts.getSessionDurations({
        uuid,
        startAt,
        endAt,
      });

      setGraphData([
        {
          data: Object.keys(sessionsOverTime).map((key) => ({
            x: key,
            y: sessionsOverTime[key],
          })),
        },
      ]);
      setIsLoading(false);
    };

    loadData();
  }, [uuid, startAt, endAt]);

  return (
    <div className="text-center text-gray-700" style={{ height: 300 }}>
      <h3 className="font-semibold text-gray-700 mb-4">Session durations</h3>
      {isLoading && <SpinnerSlate style={{ height: 200 }} />}
      {!isLoading && graphData.length && (
        <ResponsiveLine
          data={graphData}
          theme={{ fontFamily: 'Inter, Arial, sans-serif' }}
          margin={{ top: 0, right: 30, bottom: 90, left: 60 }}
          colors="#7C3AED"
          enablePoints={false}
          xScale={{
            type: 'linear',
          }}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            tickValues: 10,
            legend: 'Time (minutes)',
            legendPosition: 'middle',
            legendOffset: 32,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Sessions',
            legendPosition: 'middle',
            legendOffset: -48,
          }}
          enableSlices="x"
          sliceTooltip={({ slice }) => (
            <NivoTooltip>
              <div>Time: {slice.points[0].data.xFormatted} mins</div>
              {slice.points.map((point) => (
                <div
                  key={point.id}
                  style={{
                    color: point.serieColor,
                    padding: '3px 0',
                  }}
                >
                  <span>Sessions: {point.data.yFormatted}</span>
                </div>
              ))}
            </NivoTooltip>
          )}
        />
      )}
    </div>
  );
};

export default AppSessionDurations;
