import { FunctionComponent, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import apiService from '../../api/apiService';
import AppLayout from '../../AppLayout';
import ModuleDetails from '../../components/charts/ModuleDetails';
import useDashboardState from '../../hooks/useDashboardState';
import DashboardControls from './DashboardControls';
import ModuleQuizAnswers from './ModuleQuizAnswers';
import ModuleSurveyAnswers from './ModuleSurveyAnswers';

const DashboardModulePage: FunctionComponent = () => {
  const { uuid, moduleName } = useParams<{
    uuid: string;
    moduleName: string;
  }>();
  const [currentApp, setCurrentApp] = useState<any>();
  const dateRange = useDashboardState((state) => state.dateRange);

  useEffect(() => {
    const loadCurrentApp = async () => {
      const data = await apiService.getAppInfo(uuid);
      setCurrentApp(data);
    };

    loadCurrentApp();
  }, [uuid]);

  return (
    <AppLayout>
      <Helmet>
        <title>Module</title>
      </Helmet>
      <div className="max-w-7xl px-4 mx-auto">
        <h1 className="text-xl text-gray-800 leading-5 mt-6 mb-2">
          {moduleName}
        </h1>
        {currentApp && <Link to={`/dashboard/${uuid}`}>{currentApp.name}</Link>}
        {currentApp && (
          <>
            <DashboardControls uuid={currentApp.uuid} />
            <div className="space-y-12 mt-6">
              <div className="bg-white p-6 shadow-lg rounded-lg">
                <h3 className="font-semibold text-gray-700 mb-4">
                  Sessions over time
                </h3>
                <ModuleDetails
                  moduleName={moduleName}
                  uuid={uuid}
                  startAt={dateRange.startDate.toString()}
                  endAt={dateRange.endDate.toString()}
                />
              </div>
              <div>
                <h3 className="text-lg text-gray-700 mb-4 px-4">
                  Survey answers
                </h3>
                <ModuleSurveyAnswers
                  startAt={dateRange.startDate.toString()}
                  endAt={dateRange.endDate.toString()}
                  moduleName={moduleName}
                  uuid={uuid}
                />
              </div>
              <div>
                <h3 className="text-lg text-gray-700 mb-4 px-4">
                  Quiz Answers
                </h3>
                <ModuleQuizAnswers
                  startAt={dateRange.startDate.toString()}
                  endAt={dateRange.endDate.toString()}
                  moduleName={moduleName}
                  uuid={uuid}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </AppLayout>
  );
};

export default DashboardModulePage;
