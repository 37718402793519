import _range from 'lodash/range';

export type SurveyAnswer = {
  label: string;
  value: number;
};

/* tslint:disable:max-line-length */
// https://en.wikipedia.org/wiki/Likert_scale
const LIKERT_MULTI_ANSWERS: SurveyAnswer[] = [
  {
    label: 'Strongly agree',
    value: 0,
  },
  {
    label: 'Agree',
    value: 1,
  },
  {
    label: 'Neither agree nor disagree',
    value: 2,
  },
  {
    label: 'Disagree',
    value: 3,
  },
  {
    label: 'Strongly disagree',
    value: 4,
  },
];

const SCALE10_ANSWERS: SurveyAnswer[] = [
  {
    label: 'Answer #0 (Not likely at all)',
    value: 0,
  },
  ..._range(1, 10).map((num: number) => ({
    label: `Answer #${num}`,
    value: num,
  })),
  {
    label: 'Answer #10 (Extremely likely)',
    value: 10,
  },
];

const surveyData = {
  questions: [
    {
      id: 'q1',
      type: 'scale10',
      title:
        'On a scale of 0-10 with 0 being “not likely at all” and 10 being “Extremely Likely”, how likely are you to recommend this VR experience to others?',
      answers: SCALE10_ANSWERS,
    },
    {
      id: 'q2',
      type: 'multi',
      title:
        'How did this VR experience influence your likelihood to attend one or more of the following classes: Managing Bias, Be The Ally, Managing Inclusion?',
      answers: [
        {
          label: 'I am more likely to attend.',
          value: 0,
        },
        {
          label: 'I am somewhat more likely to attend.',
          value: 1,
        },
        {
          label: 'This experience has no impact on my likelihood to attend.',
          value: 2,
        },
        {
          label: 'I am somewhat less likely to attend.',
          value: 3,
        },
        {
          label: 'I am much less likely to attend.',
          value: 4,
        },
        {
          label: 'I have already taken at least one of these classes.',
          value: 5,
        },
      ],
    },
    {
      id: 'q3',
      type: 'multi',
      title:
        'After going through this VR experience, do you intend to learn more about how to be an ally to others in the workplace and/or intend to shift your behavior to be more allyship-focused?',
      answers: [
        {
          label: 'Yes',
          value: 0,
        },
        {
          label: 'No',
          value: 1,
        },
        {
          label: 'Maybe',
          value: 2,
        },
      ],
    },
    {
      id: 'q4',
      type: 'multi',
      title:
        'How much do you agree/disagree with the following statement: after going through this VR experience, I better understand the micro-aggressions faced by many women in tech.',
      answers: LIKERT_MULTI_ANSWERS,
    },
    {
      id: 'q5',
      type: 'multi',
      title:
        'How much do you agree/disagree with the following statement: Compared to a 2D video, this VR experience is more likely to influence my likelihood to commit to allyship behavior.',
      answers: LIKERT_MULTI_ANSWERS,
    },
  ],
};

export const WELLNESS_DEFAULT_ANSWERS = [
  {
    label: 'Not stressed at all',
    value: 0,
  },
  ..._range(1, 4).map((num: number) => ({
    label: `A little Stressed`,
    value: num,
  })),
  ..._range(4, 8).map((num: number) => ({
    label: `Stressed`,
    value: num,
  })),
  ..._range(8, 11).map((num: number) => ({
    label: `Severely Stressed`,
    value: num,
  })),
];

export default surveyData;
