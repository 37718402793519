import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import apiService from '../../api/apiService';
import useSortedData from '../../hooks/useSortedData';
import { formatMsReadable, formatRoundedBiggestUnit } from '../../utils/format';
import SortableHeader from '../SortableHeader';
import SpinnerSlate from '../SpinnerSlate';

interface Props {
  uuid: string;
  startAt: string;
  endAt: string;
}

const ModuleDataTable: FunctionComponent<Props> = (props) => {
  const [chartData, setChartData] = useState<any[]>([]);
  const [error, setError] = useState<string>();

  useEffect(() => {
    const fetchModuleData = async () => {
      try {
        const { data } = await apiService.charts.getAppLaunches({
          uuid: props.uuid,
          startAt: props.startAt,
          endAt: props.endAt,
        });

        setChartData(
          Object.keys(data).map((key) => ({
            moduleName: key,
            ...data[key],
            averageTime: data[key].totalTime / data[key].launches,
          })),
        );
      } catch (error) {
        console.error(error);
        setError(error.toString());
      }
    };
    fetchModuleData();
  }, [props.uuid, props.startAt, props.endAt]);

  const { sortedData, requestSort, sortState } = useSortedData(chartData || []);

  if (!chartData) {
    return <SpinnerSlate style={{ height: 300 }} />;
  }

  if (error) {
    return (
      <div
        className="flex items-center justify-center text-red-700"
        style={{ height: 300 }}
      >
        {error}
      </div>
    );
  }

  return (
    <table className="w-full whitespace-nowrap">
      <thead className="text-left bg-gray-50 border border-gray-200 text-gray-500 text-sm uppercase">
        <tr>
          <SortableHeader
            label="Module"
            sortKey="moduleName"
            requestSort={requestSort}
            isSortedAsc={sortState.direction === 'asc'}
            isSorted={
              sortState.sortKey === 'moduleName' && !!sortState.direction
            }
          />
          <SortableHeader
            label="Launches"
            sortKey="launches"
            requestSort={requestSort}
            isSortedAsc={sortState.direction === 'asc'}
            isSorted={sortState.sortKey === 'launches' && !!sortState.direction}
          />
          <SortableHeader
            label="Total time"
            sortKey="totalTime"
            requestSort={requestSort}
            isSortedAsc={sortState.direction === 'asc'}
            isSorted={
              sortState.sortKey === 'totalTime' && !!sortState.direction
            }
          />
          <SortableHeader
            label="Average time"
            sortKey="averageTime"
            requestSort={requestSort}
            isSortedAsc={sortState.direction === 'asc'}
            isSorted={
              sortState.sortKey === 'averageTime' && !!sortState.direction
            }
          />
        </tr>
      </thead>
      <tbody>
        {sortedData
          .filter((moduleData) => !isNaN(moduleData.totalTime))
          .map((moduleData) => (
            <Fragment key={moduleData.moduleName}>
              <tr className="text-left border border-gray-200 align-top">
                <td className="text-left p-2">
                  <div>
                    <div className="flex items-center">
                      <Link
                        className="font-semibold"
                        to={`/dashboard/${props.uuid}/${moduleData.moduleName}`}
                      >
                        {moduleData.moduleName}
                      </Link>
                    </div>
                  </div>
                </td>
                <td className="p-2 text-right">{moduleData.launches}</td>
                <td className="p-2 text-right">
                  <abbr title={formatMsReadable(moduleData.totalTime * 1000)}>
                    {formatRoundedBiggestUnit(moduleData.totalTime * 1000)}
                  </abbr>
                </td>
                <td className="p-2 text-right">
                  <abbr title={formatMsReadable(moduleData.averageTime * 1000)}>
                    {formatRoundedBiggestUnit(moduleData.averageTime * 1000)}
                  </abbr>
                </td>
              </tr>
            </Fragment>
          ))}
      </tbody>
    </table>
  );
};

export default ModuleDataTable;
