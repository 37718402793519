import { FormEvent, FunctionComponent, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useHistory } from 'react-router-dom';
import apiService from '../../api/apiService';
import Alert from '../../components/Alert';
import Button from '../../components/Button';
import ExternalLink from '../../components/ExternalLink';
import WarningIcon from '../../components/icons/WarningIcon';
import LoginBrand from '../../components/LoginBrand';
import StarsBackgroundContainer from '../../components/StarsBackgroundContainer';
import { formatAxiosError } from '../../utils/format';

type TPasswordValidation = {
  label: string;
  met: boolean;
};

type TRegisterState = {
  email: string;
  password: string;
  username: string;
  agree_to_policies: boolean;
  children?: any;
  passwordValidations: TPasswordValidation[];
};

const validatePassword = (password: string) => {
  return [
    { label: 'At least 8 characters', met: password.length >= 8 },
    { label: 'At least 1 lowercase letter', met: /[a-z]/.test(password) },
    { label: 'At least 1 uppercase letter', met: /[A-Z]/.test(password) },
    { label: 'At least 1 number', met: /\d/.test(password) },
    {
      label: 'At least 1 special character',
      met: /(?=.*[*.!@#$%^&(){}[\]:;<>,/~_+-=|\\])/g.test(password),
    },
  ];
};

interface Props {
  siteConfig?: {
    // TODO
    maintenance: boolean;
  };
}

const RegisterPage: FunctionComponent<Props> = (props) => {
  const history = useHistory();
  const [formState, setFormState] = useState<TRegisterState>({
    email: '',
    password: '',
    username: '',
    agree_to_policies: false,
    passwordValidations: validatePassword(''),
  });

  const [error, setError] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  const normalFormContent = () => (
    <div className="p-4 relative text-gray-800">
      <h1 className="text-xl font-light leading-5 mb-6 text-center">
        Create An Account
      </h1>
      {error && <Alert>{error}</Alert>}
      <form onSubmit={submitRegister} className="space-y-6">
        <div>
          <label className="label" htmlFor="username" tabIndex={2}>
            Username
          </label>
          <input
            type="text"
            className="textfield"
            id="username"
            autoComplete="nickname"
            placeholder="Your username"
            onChange={onUsernameChanged}
            autoFocus
            required
          />
        </div>

        <div>
          <label className="label" htmlFor="email" tabIndex={1}>
            Email
          </label>
          <input
            type="text"
            className="textfield"
            id="email"
            autoComplete="email"
            onChange={onEmailChanged}
            placeholder="hello@forwardxp.com"
            required
          />
        </div>

        <div>
          <label className="label" htmlFor="password" tabIndex={3}>
            Password
          </label>
          <div className="text-gray-600">
            <p>Please choose a password with:</p>
            <ul className="mb-3">
              {formState.passwordValidations.map((validation, index) => (
                <li key={`validation-${index}`}>
                  <span style={{ minWidth: 16, display: 'inline-block' }}>
                    {validation.met && '✓'}
                  </span>
                  {validation.label}
                </li>
              ))}
            </ul>
            <input
              type="password"
              className="textfield"
              id="password"
              autoComplete="new-password"
              placeholder="Keep it secret, keep it safe..."
              minLength={8}
              onChange={onPasswordChanged}
              required
            />
          </div>
        </div>

        <div>
          <label className="label" htmlFor="agree_policies">
            <input
              type="checkbox"
              name="agree_to_policies"
              id="agree_policies"
              onChange={onAgreeToPoliciesChanged}
            />
            <span className="ml-2">
              I agree to the ForwardXP{' '}
              <ExternalLink
                href="https://www.forwardxp.com/legal"
                className="text-semibold text-blue-700 hover:underline"
              >
                terms of use
              </ExternalLink>
              .
            </span>
          </label>
        </div>

        <Button
          loading={isLoading}
          large
          className="w-full"
          variant="brand"
          type="submit"
        >
          Create Account
        </Button>

        <hr className="rio-login-divider" />

        <div>
          Already have an account? <Link to="/login">Log in</Link>.
        </div>
      </form>
    </div>
  );

  const maintenanceFormContent = () => (
    <div className="p-4" style={{ position: 'relative' }}>
      <div className="rio-form">
        <h1 className="text-xl font-light leading-5 text-yellow-400 text-center">
          <WarningIcon className="inline-block" /> Site maintenance in progress
        </h1>

        <p className=" text-center mt-3">Registration temporarily disabled.</p>

        <p className=" text-center mt-3">Please come back later.</p>

        <hr />

        <div className="mt-3 text-xl font-light leading-5">
          Already have an account? <Link to="/login">Log in</Link>.
        </div>
      </div>
    </div>
  );

  const onEmailChanged = (event: any): void => {
    setFormState({
      ...formState,
      email: event.target.value,
    });
  };

  const onUsernameChanged = (event: any): void => {
    setFormState({
      ...formState,
      username: event.target.value,
    });
  };

  const onPasswordChanged = (event: any): void => {
    setFormState({
      ...formState,
      password: event.target.value,
      passwordValidations: validatePassword(event.target.value),
    });
  };

  const onAgreeToPoliciesChanged = (event: any): void => {
    setFormState({ ...formState, agree_to_policies: event.target.checked });
  };

  const submitRegister = (event: FormEvent) => {
    event.preventDefault();

    // Don't allow the user to submit without a proper password
    const passwordIsValid = formState.passwordValidations.every(
      (validation) => validation.met,
    );

    if (!passwordIsValid) {
      setError('Invalid password');
      return;
    }

    const [validationError, validatedParams] = validateRegisterParams({
      email: formState.email,
      password: formState.password,
      username: formState.username,
      agree_to_policies: formState.agree_to_policies,
    });

    if (validationError) {
      setError(validationError);
      return;
    }

    setIsLoading(true);

    apiService
      .register(validatedParams)
      .then(() => {
        if (typeof window.ga === 'function') {
          window.ga('send', 'event', 'register');
        }

        history.push('/verify/email');
      })
      .catch((error) => {
        setError(formatAxiosError(error, error.toString()));
        setIsLoading(false);
      });
  };

  return (
    <div className="h-full">
      <Helmet>
        <title>Register</title>
      </Helmet>
      <StarsBackgroundContainer width={430}>
        <LoginBrand />
        <div>
          {!(props.siteConfig && props.siteConfig.maintenance) &&
            normalFormContent()}
          {props.siteConfig &&
            props.siteConfig.maintenance &&
            maintenanceFormContent()}
        </div>
      </StarsBackgroundContainer>
    </div>
  );
};

function cleanupString(str: string) {
  if (typeof str !== 'string') {
    return '';
  }
  return str.trim();
}

function validateRegisterParams(params: {
  email: string;
  username: string;
  password: string;
  agree_to_policies?: boolean;
}) {
  const newParams: any = {
    email: cleanupString(params.email),
    username: cleanupString(params.username),
    password: params.password,
    agree_to_policies: !!params.agree_to_policies ? 1 : 0,
  };

  const missingFields = [];

  if (!newParams.email) {
    missingFields.push('Email');
  }

  if (!newParams.username) {
    missingFields.push('Username');
  }

  if (!newParams.password) {
    missingFields.push('Password');
  }

  if (missingFields.length > 0) {
    return [`Missing value for ${missingFields.join(', ')}`];
  }

  if (!newParams.agree_to_policies) {
    return [
      'You must agree to our Terms of Use, Privacy Policy, and Cookies Policy in order to create an account.',
    ];
  }

  return [null, newParams];
}

export default RegisterPage;
