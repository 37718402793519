import { ResponsivePie } from '@nivo/pie';
import { FunctionComponent, useEffect, useState } from 'react';
import apiService from '../../api/apiService';
import SpinnerSlate from '../SpinnerSlate';

interface Props {
  uuid: string;
  questionId: string;
  startAt: string;
  endAt: string;
  title?: string;
  colors?: string[];
}

const SurveyAnswersHistogram: FunctionComponent<Props> = ({
  uuid,
  questionId,
  startAt,
  endAt,
  title,
  colors,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [data, setData] = useState<any>();

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      setError(undefined);

      try {
        const { data } = await apiService.charts.getSurveyAnswers({
          uuid,
          chartId: `question_answer_hist_${questionId}`,
          startAt,
          endAt,
        });

        const chartInfo = data.chartInfo as { value: number; id: string }[];

        if (chartInfo.some((item) => item.value !== 0)) {
          setData(chartInfo.filter((item) => item.value > 0));
        }
      } catch (error) {
        setError(error.message);
      }

      setIsLoading(false);
    };
    loadData();
  }, [uuid, startAt, endAt, questionId]);

  if (isLoading) {
    return <SpinnerSlate style={{ height: 300 }} />;
  }

  if (!data || error) {
    return (
      <div
        className="border border-gray-100 flex items-center rounded justify-center bg-gray-50"
        style={{ height: 300 }}
      >
        {error || 'No data'}
      </div>
    );
  }

  return (
    <div style={{ height: 300 }}>
      <h3 className="text-center font-semibold text-gray-700">
        {title || 'Answer distribution'}
      </h3>
      <ResponsivePie
        data={data}
        theme={{ fontFamily: 'Inter, Arial, sans-serif' }}
        colors={colors || ['#1D4ED8', '#A21CAF', '#7E22CE', '#4338CA']}
        margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
        innerRadius={0.33}
        padAngle={2}
        cornerRadius={2}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: 'color' }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor="#fff"
      />
    </div>
  );
};

export default SurveyAnswersHistogram;
