import { FormEvent, FunctionComponent, useState } from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps, useHistory, useParams } from 'react-router-dom';
import apiService from '../../api/apiService';
import Button from '../../components/Button';
import LoginBrand from '../../components/LoginBrand';
import StarsBackgroundContainer from '../../components/StarsBackgroundContainer';

export type TRegisterProps = RouteComponentProps<any, any>;

const RegisterPendingPage: FunctionComponent = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { email } = useParams<{ email: string }>();
  const history = useHistory();

  const goLogin = (event: any) => {
    event.preventDefault();
    (window as any).location.href = '/login';
  };

  const resendVerification = (event: FormEvent) => {
    event.preventDefault();

    setIsSubmitting(true);

    apiService
      .sendAddressVerificationEmail(email)
      .then(() => {
        history.push('/verify/email');
      })
      .catch((error) => {
        console.error(error);
        history.push('/verify/error');
      });
  };

  return (
    <div className="h-full">
      <Helmet>
        <title>Registration Verification Pending</title>
      </Helmet>
      <StarsBackgroundContainer width={420}>
        <LoginBrand />
        <div>
          <div className="p-4">
            <h1 className="text-lg leading-5 mb-6 text-gray-700 text-center">
              Your account is pending email verification.
            </h1>
            <p className="mt-3">
              Please check your email for a mail from <b>hello@forwardxp.com</b>
              .
            </p>
            <p className="mt-3">
              Note: The email might have been put in your JUNK or SPAM folder.
            </p>

            <Button
              variant="brand"
              className="w-full mt-6"
              onClick={resendVerification}
            >
              Resend Verification Email
            </Button>
            <Button
              loading={isSubmitting}
              className="w-full mt-3"
              onClick={goLogin}
            >
              Log In
            </Button>
          </div>
        </div>
      </StarsBackgroundContainer>
    </div>
  );
};

export default RegisterPendingPage;
