import React from 'react';
import ReactDOM from 'react-dom';
import RootNode from './RootNode';
import './stylesheets/index.output.css';

ReactDOM.render(
  <React.StrictMode>
    <RootNode />
  </React.StrictMode>,
  document.getElementById('root'),
);
