import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router-dom';
import Alert from '../../components/Alert';
import WarningIcon from '../../components/icons/WarningIcon';
import UserSettingsNav from './UserSettingsNav';

const AppSettingsLayout = (props: any) => {
  const { location } = useHistory();
  const { error } = useParams<{ error?: string }>();

  return (
    <div className="w-full mx-auto relative">
      <Helmet>
        <title>Settings</title>
      </Helmet>

      <div className="flex w-full max-w-5xl mx-auto py-12">
        <div className="w-2/5">
          <UserSettingsNav location={location} {...props} />
        </div>
        <div className="w-4/5 p-4 md:p-6 ml-6 bg-white shadow rounded">
          {error && (
            <Alert>
              <div className="flex">
                <WarningIcon className="mr-2 mt-1" />
                <div>
                  <p className="font-bold mb-1">Error</p>
                  <h2>{error}</h2>
                </div>
              </div>
            </Alert>
          )}
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default AppSettingsLayout;
