import { FunctionComponent } from 'react';
import version from '../version.json';
import ExternalLink from './ExternalLink';

const VERSION_STRING = `${version.major}.${version.minor}.${version.patch} (${version.tag})`;

const SiteFooter: FunctionComponent = () => (
  <div className="bg-gray-100 mt-20">
    <div className="text-gray-600 text-xs text-center px-6 py-3">
      STARS&trade; &copy; 2016-2023 ForwardXP, Inc. &mdash; v{VERSION_STRING}{' '}
      &mdash;{' '}
      <ExternalLink
        href="https://www.forwardxp.com/legal"
        className="text-gray-700 hover:text-indigo-700"
      >
        Terms of Use
      </ExternalLink>
    </div>
  </div>
);

export default SiteFooter;
