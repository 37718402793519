import cx from 'classnames';
import { FunctionComponent } from 'react';
import { generatePath, Link, useLocation, useParams } from 'react-router-dom';

const SECTIONS = [
  { title: 'Settings', url: '/admin/users/:userId' },
  { title: 'Permissions', url: '/admin/permissions/:userId' },
];

const AdminSideNav: FunctionComponent = () => {
  const { pathname } = useLocation();
  const { userId } = useParams<{ userId: string }>();

  return (
    <div className="bg-white rounded-lg p-2 shadow">
      <h2 className="px-2 py-4 font-semibold text-black text-lg">
        Admin Settings
      </h2>
      <ul className="space-y-1">
        {SECTIONS.map(({ title, url }) => {
          const itemPath = generatePath(url, { userId });
          return (
            <li key={title}>
              <Link
                to={itemPath}
                className={cx(
                  'block p-2 rounded text-gray-700 hover:bg-gray-100 hover:text-gray-800',
                  {
                    'font-semibold bg-gray-200': pathname === itemPath,
                  },
                )}
              >
                {title}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default AdminSideNav;
