import { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';
import Button from '../../components/Button';
import LoginBrand from '../../components/LoginBrand';
import StarsBackgroundContainer from '../../components/StarsBackgroundContainer';

const RegisterVerifiedPage: FunctionComponent = () => {
  const goHome = (event: any) => {
    event.preventDefault();
    window.location.href = '/';
  };

  return (
    <div className="h-full">
      <Helmet>
        <title>Email Verified</title>
      </Helmet>

      <StarsBackgroundContainer width={400}>
        <LoginBrand />
        <div>
          <div className="p-4">
            <h1 className="text-xl leading-5 mb-6 text-gray-700 text-center">
              Your email address has been verified
            </h1>
            <Button className="w-full mt-4" variant="brand" onClick={goHome}>
              View Dashboard
            </Button>
          </div>
        </div>
      </StarsBackgroundContainer>
    </div>
  );
};

export default RegisterVerifiedPage;
