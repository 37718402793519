import React, {
  ChangeEvent,
  FormEvent,
  FunctionComponent,
  useState,
} from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router';
import apiService from '../../api/apiService';
import Alert from '../../components/Alert';
import Button from '../../components/Button';
import LoginBrand from '../../components/LoginBrand';
import StarsBackgroundContainer from '../../components/StarsBackgroundContainer';

const ResetPasswordPage: FunctionComponent = () => {
  const history = useHistory();
  const { token } = useParams<{ token: string }>();

  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [error, setError] = useState<string>();

  const onPasswordChanged = (event: ChangeEvent<HTMLInputElement>): void => {
    setPassword(event.target.value);
  };

  const onPasswordConfirmationChanged = (
    event: ChangeEvent<HTMLInputElement>,
  ): void => {
    setPassword2(event.target.value);
  };

  const submitChangePassword = (event: FormEvent) => {
    event.preventDefault();
    setError(undefined);

    if (!password || password !== password2) {
      setError(`Passwords must exist and must match each other`);
      return;
    }

    const combinedParams = {
      password,
      token,
    };

    apiService
      .resetPassword(combinedParams)
      .then(() => {
        if (typeof window.ga === 'function') {
          window.ga('send', 'event', 'finish_reset_password');
        }

        history.push('/reset/success');
      })
      .catch((error) => {
        setError(error.toString());
      });
  };

  return (
    <div className="h-full">
      <Helmet>
        <title>Reset Password</title>
      </Helmet>
      <StarsBackgroundContainer width={400}>
        <LoginBrand />
        <div>
          <div className="p-4">
            <h1 className="text-xl leading-5 mb-6 text-gray-700 text-center">
              Reset your password
            </h1>
            {error && <Alert>{error}</Alert>}
            <div className="rio-form">
              <form onSubmit={submitChangePassword} className="space-y-6">
                <div>
                  <label className="label" htmlFor="password1" tabIndex={1}>
                    Password
                  </label>
                  <input
                    type="password"
                    className="textfield"
                    id="password1"
                    autoComplete="new-password"
                    onChange={onPasswordChanged}
                    autoFocus
                  />
                </div>

                <div>
                  <label className="label" htmlFor="password2" tabIndex={3}>
                    Password Confirmation
                  </label>
                  <input
                    type="password"
                    className="textfield"
                    id="password2"
                    autoComplete="new-password"
                    onChange={onPasswordConfirmationChanged}
                  />
                </div>

                <Button large variant="brand" className="w-full" type="submit">
                  Change Password
                </Button>
              </form>
            </div>
          </div>
        </div>
      </StarsBackgroundContainer>
    </div>
  );
};

export default ResetPasswordPage;
