import { FunctionComponent, useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import apiService from '../../api/apiService';
import AppLayout from '../../AppLayout';
import DashboardContent from './DashboardContent';
import DashboardControls from './DashboardControls';

const DashboardPage: FunctionComponent = () => {
  const { uuid } = useParams<{ uuid: string }>();
  const [currentApp, setCurrentApp] = useState<any>();

  useEffect(() => {
    const loadCurrentApp = async () => {
      const data = await apiService.getAppInfo(uuid);
      setCurrentApp(data);
    };

    loadCurrentApp();
  }, [uuid]);

  return (
    <AppLayout>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <div className="max-w-7xl px-4 mx-auto">
        <h1 className="text-xl text-gray-800 leading-5 mt-6">
          {currentApp ? currentApp.name : 'No app selected'}
        </h1>
        {currentApp && (
          <>
            <DashboardControls uuid={currentApp.uuid} showExportButton />
            <DashboardContent uuid={currentApp.uuid} />
          </>
        )}
      </div>
    </AppLayout>
  );
};

export default DashboardPage;
