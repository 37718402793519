import { ChangeEvent, FunctionComponent, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useHistory } from 'react-router-dom';
import apiService from '../../api/apiService';
import AppLayout from '../../AppLayout';
import Alert from '../../components/Alert';
import Button from '../../components/Button';
import AppSettingsLayout from './AppSettingsLayout';

const UserPasswordSettingsPage: FunctionComponent = () => {
  const history = useHistory();
  const [currentPassword, setCurrentPassword] = useState('');
  const [removeCustomizations] = useState(true);
  const [continueDeleteAccount, setContinueDeleteAccount] = useState(false);
  const [error, setError] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  const onKeepAccount = () => {
    history.push('/settings');
  };

  const onContinueDeleteAccount = () => {
    setContinueDeleteAccount(true);
  };

  const onChangeCurrentPassword = (ev: ChangeEvent<HTMLInputElement>) => {
    setCurrentPassword(ev.target.value);
  };

  const onSubmit = () => {
    if (!currentPassword) {
      setError('Missing current password');
      return;
    }

    setError(undefined);

    const msg = `Are you sure you want to delete your account?`;

    if (!window.confirm(msg)) {
      return;
    }

    setIsLoading(true);

    const saveParams = {
      oldPassword: currentPassword,
      removeCustomizations: removeCustomizations,
    };

    apiService
      .saveDeleteAccountSettings(saveParams)
      .then(() => {
        window.location.href = '/logout';
      })
      .catch((error) => {
        setError(error.toString());
      });
  };

  return (
    <AppLayout>
      <AppSettingsLayout>
        <Helmet>
          <title>Delete Account</title>
        </Helmet>

        {error && <Alert>{error}</Alert>}

        <div className="text-gray-800">
          <h1 className="text-3xl">Delete Your Account</h1>
          <h1 className="text-gray-600">We're sorry to see you go.</h1>
          <h1 className="mt-8">Before you go...</h1>

          <hr className="dark-rule my-3" />
          <div className="my-6">
            <ul className="list-disc list-inside">
              <li>
                If you want to change your email address,{' '}
                <Link to="/settings/email">click here</Link>.
              </li>
              <li>
                If you want to change your username,{' '}
                <Link to="/settings/username">click here</Link>.
              </li>
              <li>
                Account deletion is <b>final</b>. There will be no way to
                restore your account once deleted.
              </li>
            </ul>
          </div>
          {!continueDeleteAccount && (
            <div className="flex">
              <Button variant="brand" onClick={onKeepAccount}>
                Never mind, keep my account
              </Button>
              <Button
                variant="destructive"
                className="ml-3"
                onClick={onContinueDeleteAccount}
              >
                Delete my account
              </Button>
            </div>
          )}
          {continueDeleteAccount && (
            <>
              <hr className="dark-rule my-3" />
              <div className="flex">
                <div className="w-3/5">
                  <div className="rio-form">
                    <div>
                      <label className="label" htmlFor="current">
                        Confirm Current Password:
                      </label>
                      <input
                        onChange={onChangeCurrentPassword}
                        type="password"
                        id="current"
                        className="textfield"
                        autoComplete="off"
                        placeholder=""
                        disabled={isLoading}
                      />
                    </div>
                  </div>

                  <Button
                    variant="destructive"
                    className="mt-4"
                    onClick={onSubmit}
                    loading={isLoading}
                  >
                    Delete my account
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>
      </AppSettingsLayout>
    </AppLayout>
  );
};

export default UserPasswordSettingsPage;
