import { AnchorHTMLAttributes, FunctionComponent } from 'react';

interface Props extends AnchorHTMLAttributes<HTMLAnchorElement> {}

const DropdownMenuAnchorLink: FunctionComponent<Props> = ({
  children,
  ...otherProps
}) => {
  return (
    <a
      className="block px-4 py-2 w-full text-left text-gray-800 hover:bg-gray-100"
      {...otherProps}
    >
      {children}
    </a>
  );
};

export default DropdownMenuAnchorLink;
