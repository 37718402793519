import cx from 'classnames';
import { FunctionComponent, HTMLAttributes } from 'react';

type Props = HTMLAttributes<HTMLSpanElement> & {
  color: 'blue' | 'red' | 'green';
};

const Tag: FunctionComponent<Props> = ({
  className,
  color,
  children,
  ...otherProps
}) => {
  const classes = cx(
    className,
    'font-medium text-xs inline-block rounded-full px-3 py-1',
    {
      'bg-blue-50 text-blue-700': color === 'blue',
      'bg-red-50 text-red-700': color === 'red',
      'bg-green-50 text-green-700': color === 'green',
    },
  );

  return (
    <span className={classes} {...otherProps}>
      {children}
    </span>
  );
};

export default Tag;
