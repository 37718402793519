/* tslint:disable:max-line-length */
import React, { FunctionComponent, useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import apiService from './api/apiService';
import DocumentMeta from './components/DocumentMeta';
import PrivateRoute from './components/PrivateRoute';
import useUserProfile from './hooks/useUserProfile';
import NotFoundPage from './pages/NotFoundPage';
import getRoutes from './routes';

if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize('UA-101747870-3');
  ReactGA.pageview(window.location.pathname + window.location.search);
}

const AppRoot: FunctionComponent = () => {
  const [routes] = useState(getRoutes());

  useEffect(function loadAppsForUser() {
    const fetchUserData = async () => {
      const currentUser = await apiService.getCurrentUser();

      if (currentUser.authenticated) {
        useUserProfile.setState({
          isAuthenticated: currentUser.authenticated,
          apps: currentUser.apps,
          name: currentUser.name,
          role: currentUser.role,
        });
      }
    };
    fetchUserData();
  }, []);

  return (
    <Router>
      <DocumentMeta />
      <Switch>
        {routes.redirectRoutes.map((route, index) => (
          <Redirect key={index} {...route} />
        ))}
        {routes.publicRoutes.map((route, index) => (
          <Route
            key={index}
            exact={route.exact}
            path={route.path}
            render={(renderProps: any) =>
              React.createElement(route.component, renderProps)
            }
          />
        ))}
        {routes.componentRoutes.map((route, index) => (
          <PrivateRoute
            key={index}
            exact={route.exact}
            path={route.path}
            render={(renderProps: any) =>
              React.createElement(route.component, renderProps)
            }
          />
        ))}
        <Route>
          <NotFoundPage />
        </Route>
      </Switch>
    </Router>
  );
};

export default AppRoot;
