import { ChangeEvent, FormEvent, FunctionComponent, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useHistory } from 'react-router-dom';
import apiService from '../api/apiService';
import Alert from '../components/Alert';
import Button from '../components/Button';
import LoginBrand from '../components/LoginBrand';
import StarsBackgroundContainer from '../components/StarsBackgroundContainer';
import { formatAxiosError } from '../utils/format';

type TLoginParams = {
  email: string;
  password: string;
  remember: boolean;
};

function cleanupString(str: string) {
  if (typeof str !== 'string') {
    return '';
  }
  return str.trim();
}

function validateLoginParams(
  params: TLoginParams,
): [string | null, TLoginParams?] {
  const newParams: TLoginParams = {
    email: cleanupString(params.email),
    password: params.password,
    remember: params.remember || false,
  };

  const missingFields = [];

  if (!newParams.email) {
    missingFields.push('Email');
  }

  if (!newParams.password) {
    missingFields.push('Password');
  }

  if (missingFields.length > 0) {
    return [`Missing value for ${missingFields.join(', ')}`];
  }

  return [null, newParams];
}

const LoginPage: FunctionComponent = () => {
  const history = useHistory();
  const [error, setError] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [formContent, setFormContent] = useState<TLoginParams>({
    email: '',
    password: '',
    remember: false,
  });

  const onEmailChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setFormContent({ ...formContent, email: event.currentTarget.value });
  };

  const onPasswordChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setFormContent({ ...formContent, password: event.currentTarget.value });
  };

  const onRememberChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setFormContent({ ...formContent, remember: event.currentTarget.checked });
  };

  const submitLogin = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setError(undefined);

    const [validationError, validatedParams] = validateLoginParams(formContent);
    if (validationError) {
      setError(validationError);
      return;
    }

    if (!validatedParams) {
      return;
    }

    setIsLoading(true);

    apiService
      .login(validatedParams)
      .then((response) => {
        const { responseURL } = response.request;
        if (
          typeof responseURL === 'string' &&
          responseURL.startsWith('/verify')
        ) {
          history.push(responseURL);
        } else {
          if (typeof window.ga === 'function') {
            window.ga('send', 'event', 'login');
          }

          // full reload page, because the core template might have different data if they have higher access (premium or what not)
          window.location.href = `/?refresh=${new Date().getTime()}`;
        }
      })
      .catch((error) => {
        setError(formatAxiosError(error, error.toString()));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="h-full">
      <Helmet>
        <title>Login</title>
      </Helmet>

      {/* <ErrorContainer type="close" /> */}
      <StarsBackgroundContainer width={400}>
        <LoginBrand />
        <div>
          <div className="p-4 relative text-gray-800">
            <h1 className="text-xl font-light leading-5 mb-6 text-center">
              Welcome to STARS
            </h1>
            <form onSubmit={submitLogin} className="space-y-6">
              {error && <Alert>{error}</Alert>}
              <div>
                <label className="label" htmlFor="email" tabIndex={1}>
                  Email
                </label>
                <input
                  type="text"
                  className="textfield"
                  id="email"
                  autoComplete="email"
                  onChange={onEmailChanged}
                  autoFocus
                />
              </div>
              <div>
                <label className="label" htmlFor="password" tabIndex={2}>
                  Password
                </label>
                <input
                  type="password"
                  className="textfield"
                  id="password"
                  autoComplete="current-password"
                  onChange={onPasswordChanged}
                />
              </div>
              <Link className="block" to="/login/forgot">
                Forgot your password?
              </Link>

              <label className="block cursor-pointer">
                <input
                  type="checkbox"
                  name="remember"
                  id="remember"
                  onChange={onRememberChanged}
                />
                <span className="ml-2">Remember Me For 30 Days</span>
              </label>

              <Button
                loading={isLoading}
                variant="brand"
                className="w-full"
                large
                type="submit"
              >
                Log in
              </Button>
              <hr />

              <div>
                Need an Account? <Link to="/register">Register</Link>.
              </div>
            </form>
          </div>
        </div>
      </StarsBackgroundContainer>
    </div>
  );
};

export default LoginPage;
