import { FunctionComponent } from 'react';
import { generatePath, Link, useParams } from 'react-router-dom';
import useUserProfile from '../hooks/useUserProfile';
import { ReactComponent as Logo } from '../images/brand/Logo_2ColorWhite.svg';
import DropdownMenu from './dropdownMenu/DropdownMenu';
import UserCircleIcon from './icons/UserCircleIcon';
import WarningIcon from './icons/WarningIcon';

interface Props {
  siteConfig?: {
    // TODO
    maintenance: boolean;
  };
}

const AuthBar: FunctionComponent<Props> = ({ siteConfig }) => {
  const { isAuthenticated, name, apps, role } = useUserProfile(
    (state) => state,
  );
  const { uuid } = useParams<{ uuid: string }>();

  const currentApp = apps.find((app) => app.uuid === uuid);

  return (
    <div className="sticky top-0 z-10 bg-gray-700">
      <div className="shadow text-white w-full max-w-7xl mx-auto">
        <div className="flex items-center justify-between px-4">
          <div className="flex items-center">
            <div className="my-3">
              <Link to="/">
                <Logo className="h-6 w-auto" />
              </Link>
            </div>
            {isAuthenticated && apps.length > 1 && (
              <div className="rio-auth-nav text-left ml-3">
                <DropdownMenu
                  label={currentApp ? currentApp.name : 'No app selected'}
                >
                  {apps.map((appInfo) => (
                    <DropdownMenu.Link
                      key={`app-${appInfo.id}`}
                      to={generatePath(`/dashboard/:uuid`, {
                        uuid: appInfo.uuid,
                      })}
                    >
                      {appInfo.name}
                    </DropdownMenu.Link>
                  ))}
                </DropdownMenu>
              </div>
            )}
          </div>

          {isAuthenticated && (
            <div>
              <DropdownMenu
                position="right"
                label={
                  <>
                    <UserCircleIcon className="mr-2" />
                    <span>{name}</span>
                  </>
                }
              >
                <DropdownMenu.Link to="/settings">Settings</DropdownMenu.Link>
                {role === 'superadmin' && (
                  <DropdownMenu.Link to="/admin/users">
                    Manage users
                  </DropdownMenu.Link>
                )}
                <DropdownMenu.Divider />
                <DropdownMenu.Link to="/logout">Logout</DropdownMenu.Link>
              </DropdownMenu>
            </div>
          )}
        </div>
      </div>
      {siteConfig && siteConfig.maintenance && (
        <div className="w-full text-center bg-yellow-50 p-2">
          <h1 className="text-lg text-yellow-700 flex items-center justify-center">
            <WarningIcon className="mr-2" /> Site Maintenance In Progress
          </h1>
          <p className="text-yellow-600">
            Running in read-only mode. Changes will not be saved.
          </p>
        </div>
      )}
    </div>
  );
};

export default AuthBar;
