import { Fragment, FunctionComponent, useState } from 'react';
import AppSessionCount from '../../components/charts/AppSessionCount';
import AppSessionDurations from '../../components/charts/AppSessionDurations';
import AppSessionsOverTime from '../../components/charts/AppSessionsOverTime';
import ModuleDataTable from '../../components/charts/ModuleDataTable';
import SessionHistogram from '../../components/charts/SessionHistogram';
import SurveyAnswersHistogram from '../../components/charts/SurveyAnswersHistogram';
import SurveyAnswersTable from '../../components/charts/SurveyAnswersTable';
import SurveyAnswerTimes from '../../components/charts/SurveyAnswerTimes';
import VR4WBResultsTable from '../../components/charts/VR4WBResultsTable';
import WellnessResultsTable from '../../components/charts/WellnessResultsTable';
import ChevronIcon from '../../components/icons/ChevronIcon';
import useDashboardState from '../../hooks/useDashboardState';
import SURVEY_DATA, { WELLNESS_DEFAULT_ANSWERS } from '../../utils/surveyData';

interface Props {
  uuid: string;
}

interface IVisualizationSetting {
  uuid: string;
  visualizations: { [key: string]: boolean };
  // HACK: storing some configuration data for a specific app here - should be pulled from server instead.
  wellnessSurveyEntries?: any[];
}

/**
 * Some apps have extra visualizations. This configuration is what displays them.
 */

const PRE_SURVEY_PROMPT = 'How stressed do you feel right now?';
const POST_SURVEY_PROMPT = 'How stressed do you feel after the experience?';
const WELLNESS_ANSWER_COLORS = [
  '#00b5c1',
  '#00bf9a',
  '#00c371',
  '#59c249',
  '#a9c000',
  '#c1b500',
  '#c59b00',
  '#c98014',
  '#cd5a21',
  '#cf3028',
];

const DEFAULT_APP_SETTINGS: any = {
  app_sessions: true,
};

const VISUALIZATIONS_PER_APP: IVisualizationSetting[] = [
  {
    // VR for Inclusion (Women in Tech)
    uuid: 'd2b794b8477711eab200b8fabfccc163',
    visualizations: {
      survey: true,
    },
  },
  {
    // VRDC Dogfooding Rollout #2
    uuid: 'app9ce8f0a4611eba8510aa96f985901',
    visualizations: {
      module_breakdown: true,
    },
  },
  {
    // VR Wellness
    uuid: '5c2672f26fceba20f421eede80b943d9',
    visualizations: {
      wellnessSurvey: true,
      app_sessions: false,
    },
    wellnessSurveyEntries: [
      // {
      //   title: 'Overall',
      //   description: '',
      //   preSurvey: {
      //     id: 'wellbeing_pre_survey',
      //     title: 'Wellbeing Pre-Survey',
      //     description: PRE_SURVEY_PROMPT
      //   },
      //   postSurvey: {
      //     id: 'wellbeing_post_survey',
      //     title: 'Wellbeing Post-Survey',
      //     description: POST_SURVEY_PROMPT,
      //   },
      // },
      {
        id: 'trip',
        title: 'Experience: TRIPP Calm',
        description: '',
        preSurvey: {
          id: 'trip_pre_survey',
          title: 'Tripp Pre-Survey',
          description: PRE_SURVEY_PROMPT,
        },
        postSurvey: {
          id: 'trip_post_survey',
          title: 'Tripp Post-Survey',
          description: POST_SURVEY_PROMPT,
        },
      },
      {
        id: 'pottery',
        title: `Experience: Let's Create! Pottery`,
        description: '',
        preSurvey: {
          id: 'pottery_pre_survey',
          title: 'Pre-Survey',
          description: PRE_SURVEY_PROMPT,
        },
        postSurvey: {
          id: 'pottery_post_survey',
          title: 'Post-Survey',
          description: POST_SURVEY_PROMPT,
        },
      },
      {
        id: 'treks',
        title: 'Experience: Nature Treks',
        description: '',
        preSurvey: {
          id: 'treks_pre_survey',
          title: 'Treks Pre-Survey',
          description: PRE_SURVEY_PROMPT,
        },
        postSurvey: {
          id: 'treks_post_survey',
          title: 'Treks Post-Survey',
          description: POST_SURVEY_PROMPT,
        },
      },
      {
        id: 'tetris',
        title: 'Experience: Tetris Effect',
        description: '',
        preSurvey: {
          id: 'tetris_pre_survey',
          title: 'Tetris Pre-Survey',
          description: PRE_SURVEY_PROMPT,
        },
        postSurvey: {
          id: 'tetris_post_survey',
          title: 'Tetris Post-Survey',
          description: POST_SURVEY_PROMPT,
        },
      },
      {
        id: 'taichi',
        title: 'Experience: Guided Taichi',
        description: '',
        preSurvey: {
          id: 'taichi_pre_survey',
          title: 'Taichi Pre-Survey',
          description: PRE_SURVEY_PROMPT,
        },
        postSurvey: {
          id: 'taichi_post_survey',
          title: 'Taichi Post-Survey',
          description: POST_SURVEY_PROMPT,
        },
      },
      {
        id: 'cubism',
        title: 'Experience: Cubism',
        description: '',
        preSurvey: {
          id: 'cubism_pre_survey',
          title: 'Cubism Pre-Survey',
          description: PRE_SURVEY_PROMPT,
        },
        postSurvey: {
          id: 'cubism_post_survey',
          title: 'Cubism Post-Survey',
          description: POST_SURVEY_PROMPT,
        },
      },
    ],
  },
  {
    // VR4WB 2022
    uuid: 'appddaed1c46e48efdc68c2f2ab0b77e',
    visualizations: {
      vr4wb_2022: true,
      // module_breakdown: true
      // app_sessions: true
    },
  },
];

type ClickToLoadProps = {
  expandedComponent: any;
  collapsedComponent?: any;
  toggleComponent: any;
};

const ClickToLoad = (props: ClickToLoadProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const component = isExpanded
    ? props.expandedComponent
    : props.collapsedComponent;
  return (
    <>
      {props.toggleComponent({ ...props, isExpanded, setIsExpanded })}
      {component}
    </>
  );
};

const DashboardContent: FunctionComponent<Props> = ({ uuid }) => {
  const appSettings = VISUALIZATIONS_PER_APP.find((x) => x.uuid === uuid);
  const visualizationSettings = {
    ...DEFAULT_APP_SETTINGS,
    ...(appSettings ? appSettings.visualizations : {}),
  };

  const dateRange = useDashboardState((state) => state.dateRange);


  console.log('dateRange is', dateRange);

  return (
    <div className="space-y-12 mt-6">
      <div className="flex flex-wrap">
        <div className="w-full md:w-1/3">
          <div className="p-3 rounded-lg bg-white shadow-lg">
            <AppSessionCount
              uuid={uuid}
              startAt={dateRange.startDate.toString()}
              endAt={dateRange.endDate.toString()}
            />
          </div>
        </div>
        <div className="w-full md:w-2/3 md:pl-6">
          <div className="p-3 rounded-lg bg-white shadow-lg">
            <AppSessionsOverTime
              uuid={uuid}
              startAt={dateRange.startDate.toString()}
              endAt={dateRange.endDate.toString()}
            />
          </div>
        </div>
      </div>

      {visualizationSettings.app_sessions && (
        <div className="w-full p-3 rounded-lg bg-white shadow-lg">
          <AppSessionDurations
            uuid={uuid}
            startAt={dateRange.startDate.toString()}
            endAt={dateRange.endDate.toString()}
          />
        </div>
      )}

      {visualizationSettings.module_breakdown && (
        <div className="w-full p-4 rounded-lg bg-white shadow-lg">
          <h2 className="text-xl leading-5 mt-4 mb-6">Module stats</h2>
          <ModuleDataTable
            uuid={uuid}
            startAt={dateRange.startDate.toString()}
            endAt={dateRange.endDate.toString()}
          />
        </div>
      )}

      {visualizationSettings.vr4wb_2022 && (
        <div className="flex flex-wrap py-6">
          {/* <ClickToLoad
            toggleComponent={(props: any) => {
              return (
                <h2
                  className="text-xl text-gray-900 font-medium cursor-pointer mb-4"
                  onClick={() => props.setIsExpanded(!props.isExpanded)}
                >
                  <ChevronIcon
                    className="w-4 h-4 inline-block"
                    direction={props.isExpanded ? 'down' : 'right'}
                  />
                  View Results by Device ID
                </h2>
              );
            }}
            expandedComponent={
              <VR4WBResultsTable
                uuid={uuid}
                startAt={dateRange.startDate.toString()}
                endAt={dateRange.endDate.toString()}
              />
            }
          /> */}
          <VR4WBResultsTable
            uuid={uuid}
            startAt={dateRange.startDate.toString()}
            endAt={dateRange.endDate.toString()}
          />
        </div>
      )}

      {visualizationSettings.wellnessSurvey &&
        appSettings &&
        appSettings.wellnessSurveyEntries &&
        appSettings.wellnessSurveyEntries.map((entry) => (
          <Fragment key={`survey-question-${entry.preSurvey.id}`}>
            <div className="w-full bg-white rounded-lg p-4 shadow-lg">
              <h2 className="text-xl text-gray-900 font-medium mb-3">
                {entry.title}
              </h2>

              {entry.id && (
                <div>
                  <SessionHistogram
                    chartId={`question_session_hist_${entry.id}`}
                    uuid={uuid}
                    startAt={dateRange.startDate.toString()}
                    endAt={dateRange.endDate.toString()}
                  />
                </div>
              )}

              <div className="flex flex-wrap py-6">
                <div className="w-full md:w-1/2 pr-3">
                  <p className="text-gray-900 text-lg mb-4">Pre-Survey</p>
                  <SurveyAnswersTable
                    questionId={entry.preSurvey.id}
                    answers={WELLNESS_DEFAULT_ANSWERS}
                    uuid={uuid}
                    startAt={dateRange.startDate.toString()}
                    endAt={dateRange.endDate.toString()}
                  />
                </div>
                <div className="w-full md:w-1/2 pl-3">
                  <p className="text-gray-900 text-lg mb-4">Post-Survey</p>
                  <SurveyAnswersTable
                    questionId={entry.postSurvey.id}
                    answers={WELLNESS_DEFAULT_ANSWERS}
                    uuid={uuid}
                    startAt={dateRange.startDate.toString()}
                    endAt={dateRange.endDate.toString()}
                  />
                </div>
              </div>

              <div className="flex flex-wrap py-6">
                <div className="w-full md:w-1/2 pr-3">
                  <SurveyAnswersHistogram
                    title="Pre-Survey Answer Distribution"
                    questionId={entry.preSurvey.id}
                    colors={WELLNESS_ANSWER_COLORS}
                    uuid={uuid}
                    startAt={dateRange.startDate.toString()}
                    endAt={dateRange.endDate.toString()}
                  />
                </div>
                <div className="w-full md:w-1/2 pl-3">
                  <SurveyAnswersHistogram
                    title="Post-Survey Answer Distribution"
                    questionId={entry.postSurvey.id}
                    colors={WELLNESS_ANSWER_COLORS}
                    uuid={uuid}
                    startAt={dateRange.startDate.toString()}
                    endAt={dateRange.endDate.toString()}
                  />
                </div>
              </div>

              <div className="flex flex-wrap py-6">
                <ClickToLoad
                  toggleComponent={(props: any) => {
                    return (
                      <h2
                        className="text-xl text-gray-900 font-medium cursor-pointer mb-4"
                        onClick={() => props.setIsExpanded(!props.isExpanded)}
                      >
                        <ChevronIcon
                          className="w-4 h-4 inline-block"
                          direction={props.isExpanded ? 'down' : 'right'}
                        />
                        View Survey Results by Device ID
                      </h2>
                    );
                  }}
                  expandedComponent={
                    <WellnessResultsTable
                      questionId={entry.id}
                      uuid={uuid}
                      startAt={dateRange.startDate.toString()}
                      endAt={dateRange.endDate.toString()}
                    />
                  }
                />
              </div>
            </div>
          </Fragment>
        ))}

      {visualizationSettings.survey &&
        SURVEY_DATA.questions.map((q) => (
          <Fragment key={`survey-question-${q.id}`}>
            <div className="w-full bg-white rounded-lg p-4 shadow-lg">
              <h2 className="text-xl text-gray-900 font-medium mb-3">
                Question {q.id.toUpperCase()}
              </h2>
              <p className="text-gray-900 text-lg mb-4">{q.title}</p>
              {q.answers && (
                <SurveyAnswersTable
                  questionId={q.id}
                  answers={q.answers}
                  uuid={uuid}
                  startAt={dateRange.startDate.toString()}
                  endAt={dateRange.endDate.toString()}
                />
              )}

              <div className="flex flex-wrap py-6">
                <div key={`${q.id}_answers`} className="w-full md:w-1/2 pr-3">
                  <SurveyAnswersHistogram
                    questionId={q.id}
                    uuid={uuid}
                    startAt={dateRange.startDate.toString()}
                    endAt={dateRange.endDate.toString()}
                  />
                </div>
                <div key={`${q.id}_times`} className="w-full md:w-1/2 pl-3">
                  <SurveyAnswerTimes
                    questionId={q.id}
                    uuid={uuid}
                    startAt={dateRange.startDate.toString()}
                    endAt={dateRange.endDate.toString()}
                  />
                </div>
              </div>
            </div>
          </Fragment>
        ))}
    </div>
  );
};

export default DashboardContent;
