import ForgotPasswordPage from './pages/ForgotPasswordPage';
import LoginPage from './pages/LoginPage';
// import PoliciesUpdatedPage from './pages/PoliciesUpdatedPage';
import RegisterPage from './pages/admin/RegisterPage';
import ResetPasswordPage from './pages/admin/ResetPasswordPage';
import UserDeleteAccountPage from './pages/settings/UserDeleteAccountPage';
import UserEmailSettingsPage from './pages/settings/UserEmailSettingsPage';
import UserPasswordSettingsPage from './pages/settings/UserPasswordSettingsPage';
// import UserProfileSettingsPage from './pages/settings/UserProfileSettingsPage';
// import CookiesPolicyPage from './pages/CookiesPolicyPage';
import ForgotPasswordTriggeredPage from './pages/admin/ForgotPasswordTriggeredPage';
// import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import RegisterErrorPage from './pages/admin/RegisterErrorPage';
import RegisterPendingPage from './pages/admin/RegisterPendingPage';
import RegisterSuccessPage from './pages/admin/RegisterSuccessPage';
import RegisterVerifiedPage from './pages/admin/RegisterVerifiedPage';
import ResetPasswordSuccessPage from './pages/admin/ResetPasswordSuccessPage';
// import TermsOfServicePage from './pages/TermsOfServicePage';
import DashboardPage from './pages/dashboard/DashboardPage';
import DashboardIndexPage from './pages/dashboard/DashboardIndexPage';
import LogoutPage from './pages/LogoutPage';
import ListUsersPage from './pages/admin/ListUsersPage';
import ManageUserPage from './pages/admin/ManageUserPage';
import ManageUserPermissionsPage from './pages/admin/ManageUserPermissionsPage';
import EventViewerPage from './pages/EventViewerPage';
import DashboardModulePage from './pages/dashboard/DashboardModulePage';

export type IRoutes = {
  init(): void;
  getRoutes(): TRoutesDef;
};

export type TRedirectRouteDef = {
  exact?: boolean;
  from: string;
  to: string;
};

export type TComponentRouteDef = {
  component: any;
  path: string;
  exact?: boolean;
};

type TRoutesDef = {
  redirectRoutes: TRedirectRouteDef[];
  componentRoutes: TComponentRouteDef[];
  publicRoutes: TComponentRouteDef[];
};

export default function getRoutes() {
  const routes: TRoutesDef = {
    redirectRoutes: [
      { exact: true, from: '/', to: '/dashboard' },
      { exact: true, from: '/settings', to: '/settings/email' },
    ],

    componentRoutes: [
      //
      // Dashboard pages
      //
      { path: '/dashboard', component: DashboardIndexPage, exact: true },
      { path: '/dashboard/:uuid', component: DashboardPage, exact: true },
      { path: '/dashboard/:uuid/:moduleName', component: DashboardModulePage },
      { path: '/event/:eventId', component: EventViewerPage },

      //
      // Settings pages
      //
      // {
      //   exact: true,
      //   path: '/settings/time',
      //   component: UserProfileSettingsPage,
      // },
      {
        exact: true,
        path: '/settings/email',
        component: UserEmailSettingsPage,
      },
      {
        exact: true,
        path: '/settings/password',
        component: UserPasswordSettingsPage,
      },
      {
        exact: true,
        path: `/settings/delete-account`,
        component: UserDeleteAccountPage,
      },

      //
      // Admin settings pages
      //
      { exact: true, path: '/admin/users', component: ListUsersPage },
      { path: '/admin/users/:userId', component: ManageUserPage },
      {
        path: '/admin/permissions/:userId',
        component: ManageUserPermissionsPage,
      },

      //
      // Legal pages
      //
      // { component: TermsOfServicePage, path: (TermsOfServicePage as any).getCanonicalPath() },
      // { component: PrivacyPolicyPage, path: (PrivacyPolicyPage as any).getCanonicalPath() },
      // { component: CookiesPolicyPage, path: (CookiesPolicyPage as any).getCanonicalPath() },
      // { component: PoliciesUpdatedPage, path: (PoliciesUpdatedPage as any).getCanonicalPath() }
    ],

    publicRoutes: [
      //
      // Registration and login pages
      //
      { component: ForgotPasswordTriggeredPage, path: `/verify/reset` },
      { component: ForgotPasswordPage, path: `/login/forgot` },
      { component: LoginPage, path: `/login`, exact: true },
      { component: RegisterPage, path: `/register` },
      { component: RegisterPendingPage, path: `/verify/pending` },
      { component: RegisterErrorPage, path: `/verify/error` },
      { component: RegisterSuccessPage, path: `/verify/email` },
      { component: RegisterVerifiedPage, path: `/verify/success` },
      { component: ResetPasswordPage, path: `/reset`, exact: true },
      { component: LogoutPage, path: '/logout' },
      { component: ResetPasswordSuccessPage, path: `/reset/success` },
    ],
  };
  return routes;
}
