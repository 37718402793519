import axios from 'axios';
import {
  IAppUserPermissionAttributes,
  TUserAuthentication,
  TPermission,
} from '../types/backend';

interface AuthResponse {
  isAuthenticated: boolean;
  isAuthorized: boolean;
  isInjected: boolean;
  credentials?: { user_id: number; name: string; keepAlive: boolean };
  error: any;
}

interface LoginParams {
  password: string;
  email: string;
  remember: boolean;
}

interface ChartQueryParams {
  uuid: string;
  startAt: string;
  endAt: string;
}

export type PermissionUpdate = {
  appId: number;
  permission: TPermission;
  granted: boolean;
};

/**
 * Transform an object into a query string.
 * @example objectToQueryParams({ startAt: "yesterday", endAt: "today" })
 * // "startAt=yesterday&endAt=today"
 *
 * TODO add tests
 */
function objectToQueryParams(params: { [key: string]: any }) {
  return Object.keys(params)
    .map((key) => {
      return `${key}=${encodeURIComponent(params[key])}`;
    })
    .join('&');
}

const apiService = {
  get: async (path: string) => {
    return axios.get(path);
  },

  whoami: async () => {
    const auth = await axios.get('/api/whoami');
    return auth.data as AuthResponse;
  },

  getCurrentUser: async () => {
    return axios
      .get('/api/current-user')
      .then(({ data }) => {
        return data as TUserAuthentication;
      })
      .catch(() => {
        return {
          authenticated: false,
        } as TUserAuthentication;
      });
  },

  getAppInfo: async (appUuid: string) => {
    const appData = await axios.get(`/api/app?appUuid=${appUuid}`);
    return appData.data;
  },

  login: async (payload: LoginParams) => {
    return axios.post(`/api/login`, payload);
  },

  logOut: async () => {
    return axios.get(`/api/logout`);
  },

  register: async (params: {
    email: string;
    username: string;
    password: string;
    agree_to_policies: 0 | 1;
  }) => {
    return axios.post(`/api/register`, params);
  },

  resetPassword: async (params: { password: string; token: string }) => {
    return axios.post(`/api/auth/reset`, params);
  },

  sendPasswordResetEmail: async (email: string) => {
    return axios.post(`/api/forgot`, { email });
  },

  saveDeleteAccountSettings: async (params: {
    oldPassword: string;
    removeCustomizations: boolean;
  }) => {
    return axios.post(`/api/settings/delete-account`, params);
  },

  sendAddressVerificationEmail: async (email: string) => {},

  admin: {
    getAllUsers: async () => {
      return axios.get('/api/admin/users');
    },

    updateUser: async (
      userId: string,
      userData: { name: string; role: string },
    ) => {
      const updatedData = await axios.post(
        `/api/admin/user?userId=${userId}`,
        userData,
      );
      return updatedData.data;
    },

    getUser: async (userId: string) => {
      return axios.get(`/api/admin/user?userId=${userId}`);
    },

    getPermissionsForUser: async (userId: string) => {
      const { data } = await axios.get(
        `/api/admin/permissions?userId=${userId}`,
      );
      return data as IAppUserPermissionAttributes[];
    },

    setAppUserPermissions: async (
      userId: string,
      permissions: PermissionUpdate[],
    ) => {
      const { data } = await axios.post(
        `/api/admin/permissions?userId=${userId}`,
        permissions,
      );

      return data;
    },

    getUserProfileSettings: async () => {
      return axios.get(`/api/settings/time`);
    },

    saveUserPasswordSettings: async (params: {
      oldPassword: string;
      newPassword: string;
    }) => {
      const hostPath = `/api/settings/password`;
      return axios.post(hostPath, params);
    },

    saveUserEmailSettings: async (params: {
      email: string;
      oldPassword: string;
    }) => {
      return axios.post(`/api/settings/email`, params);
    },

    resendEmailChangeVerification: async () => {
      return axios.post(`/api/settings/email/resend`);
    },

    getUserEmailSettings: async () => {
      return axios.get(`/api/settings/email`);
    },
  },

  events: {
    getEvent: async (eventId: string) => {
      return axios.get(`/api/event?eventId=${eventId}`);
    },
  },

  charts: {
    getSessionCount: async (params: ChartQueryParams) => {
      const sessionCount = await axios.get(
        `/api/sessionCount?${objectToQueryParams(params)}`,
      );
      return sessionCount.data;
    },

    getSessionsOverTime: async (params: ChartQueryParams) => {
      const sessionCount = await axios.get(
        `/api/sessionsOverTime?${objectToQueryParams(params)}`,
      );
      return sessionCount.data;
    },

    getSessionDurations: async (params: ChartQueryParams) => {
      const sessionCount = await axios.get(
        `/api/sessionDurations?${objectToQueryParams(params)}`,
      );
      return sessionCount.data;
    },

    getModuleSurveyResults: async (params: {
      uuid: string;
      startAt: string;
      endAt: string;
      moduleName: string;
    }) => {
      return axios.get(
        `/api/moduleSurveyResults?${objectToQueryParams(params)}`,
      );
    },

    getModuleQuizResults: async (params: {
      uuid: string;
      startAt: string;
      endAt: string;
      moduleName: string;
    }) => {
      return axios.get(`/api/moduleQuizResults?${objectToQueryParams(params)}`);
    },

    getModuleRecords: async (
      uuid: string,
      moduleName: string,
      startAt?: string,
      endAt?: string,
    ) => {
      return axios.get(
        `/api/moduleEvents?${objectToQueryParams({
          uuid,
          moduleName,
          startAt,
          endAt,
        })}`,
      );
    },

    getAppLaunches: async (params: ChartQueryParams) => {
      return axios.get(`/api/appLaunches?${objectToQueryParams(params)}`);
    },

    getGenericAppLaunches: async (params: ChartQueryParams) => {
      return axios.get(
        `/api/genericAppLaunches?${objectToQueryParams(params)}`,
      );
    },

    getSurveyAnswers: async (
      params: ChartQueryParams & { chartId: string },
    ) => {
      return axios.get(`/api/v1/apps/charts?${objectToQueryParams(params)}`);
    },
  },
};

export default apiService;
