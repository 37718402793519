import cx from 'classnames';
import { FunctionComponent, SelectHTMLAttributes } from 'react';

const SelectInput: FunctionComponent<
  SelectHTMLAttributes<HTMLSelectElement>
> = ({ className, children, ...otherProps }) => {
  const classes = cx(
    className,
    'appearance-none w-full border px-3 py-2 rounded transition focus:outline-none focus:shadow-outline focus:ring ring-blue-200 focus:border-blue-600',
    {
      'bg-gray-100 cursor-not-allowed': otherProps.disabled,
    },
  );

  return (
    <select
      {...otherProps}
      style={{
        // tslint:disable-next-line:max-line-length
        backgroundImage: `url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='none'%3E%3Cpath d='M7 7l3-3 3 3m0 6l-3 3-3-3' stroke='%239fa6b2' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E")`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right .5rem center',
        backgroundSize: '1.5em 1.5em',
      }}
      className={classes}
    >
      {children}
    </select>
  );
};

export default SelectInput;
