import { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import Button from '../../components/Button';
import LoginBrand from '../../components/LoginBrand';
import StarsBackgroundContainer from '../../components/StarsBackgroundContainer';

const ResetPasswordSuccessPage: FunctionComponent = () => {
  const history = useHistory();
  const goHome = (event: any) => {
    event.preventDefault();
    history.push('/login');
  };

  return (
    <div className="h-full">
      <Helmet>
        <title>Reset Password Successful</title>
      </Helmet>
      <StarsBackgroundContainer width={400}>
        <LoginBrand />
        <div>
          <div className="p-4">
            <h1 className="text-lg leading-5 mb-6 text-gray-700 text-center">
              Your Password Has Been Changed
            </h1>
            <Button className="w-full mt-4" variant="brand" onClick={goHome}>
              View Dashboard
            </Button>
          </div>
        </div>
      </StarsBackgroundContainer>
    </div>
  );
};

export default ResetPasswordSuccessPage;
