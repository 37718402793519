import { FunctionComponent } from 'react';

const Alert: FunctionComponent = ({ children }) => {
  return (
    <div
      className="rounded bg-red-100 text-red-800 px-6 py-4 mb-6"
      role="alert"
    >
      {children}
    </div>
  );
};

export default Alert;
