import create from 'zustand';
import { combine } from 'zustand/middleware';
import { DateTime } from 'luxon';

export type DateRange = {
  startDate: Date;
  endDate: Date;
};

const useDashboardState = create(
  combine(
    {
      dateRange: {
        startDate: DateTime.now()
          .minus({ month: 1 })
          .toJSDate(),
        endDate: DateTime.now().toJSDate(),
      },
    },
    (set) => ({
      setStartDate: (startDate: Date) =>
        set((state) => ({
          dateRange: { startDate, endDate: state.dateRange.endDate },
        })),
      setEndDate: (endDate: Date) =>
        set((state) => ({
          dateRange: { startDate: state.dateRange.startDate, endDate },
        })),
      setDateRange: (dateRange: DateRange) => set({ dateRange }),
    }),
  ),
);

export default useDashboardState;
