import { FunctionComponent } from 'react';
import DatePicker from 'react-datepicker';
import Button from '../../components/Button';
import useDashboardState from '../../hooks/useDashboardState';

interface Props {
  uuid: string;
  showExportButton?: boolean;
}

const DashboardControls: FunctionComponent<Props> = ({
  uuid,
  showExportButton,
}) => {
  const dateRange = useDashboardState((state) => state.dateRange);
  const setDateRange = useDashboardState((state) => state.setDateRange);

  const setStartDate = (startDate: Date) => {
    setDateRange({
      startDate: startDate,
      endDate: dateRange.endDate,
    });
  };

  const setEndDate = (endDate: Date) => {
    setDateRange({
      startDate: dateRange.startDate,
      endDate: endDate,
    });
  };

  const doExportCSV = () => {
    window.location.href = `/api/export?uuid=${uuid}&startAt=${dateRange.startDate.toString()}&endAt=${dateRange.endDate.toString()}&format=csv`;
  };

  const doExportJSON = () => {
    window.location.href = `/api/export?uuid=${uuid}&startAt=${dateRange.startDate.toString()}&endAt=${dateRange.endDate.toString()}&format=json`;
  };

  return (
    <div className="space-y-2 md:space-y-0 md:space-x-4 md:flex justify-end items-end pr-4 mt-6">
      <div className="flex-grow-0">
        <label className="label" htmlFor="startDate">
          Start Date:
        </label>
        <DatePicker
          id="startDate"
          className="textfield"
          selected={dateRange.startDate}
          onChange={(d: Date) => {
            if (d) {
              setStartDate(d);
            }
          }}
          selectsStart
          startDate={dateRange.startDate}
          endDate={dateRange.endDate}
          autoComplete="off" // Don't use value cached by browser on reload
        />
      </div>
      <div className="flex-grow-0">
        <label className="label" htmlFor="endDate">
          End Date:
        </label>
        <DatePicker
          id="endDate"
          className="textfield"
          selected={dateRange.endDate}
          onChange={(d: Date) => {
            if (d) {
              setEndDate(d);
            }
          }}
          selectsEnd
          startDate={dateRange.startDate}
          endDate={dateRange.endDate}
          minDate={dateRange.startDate}
          autoComplete="off" // Don't use value cached by browser on reload
        />
      </div>
      {showExportButton && (
        <div className="flex-grow-0">
          <Button onClick={doExportCSV}>Export CSV</Button>
        </div>
      )}
      {showExportButton && (
        <div className="flex-grow-0">
          <Button onClick={doExportJSON}>Export JSON</Button>
        </div>
      )}
    </div>
  );
};

export default DashboardControls;
