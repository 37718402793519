import { FunctionComponent } from 'react';
import AuthBar from './components/AuthBar';
import ErrorBoundary from './components/ErrorBoundary';
import SiteFooter from './components/SiteFooter';

const AppLayout: FunctionComponent = ({ children, ...otherProps }) => (
  <div className="flex flex-col min-h-full">
    <AuthBar {...otherProps} />
    <div className="flex-grow">
      <ErrorBoundary>
        <div className="h-full">{children}</div>
      </ErrorBoundary>
    </div>
    <SiteFooter {...otherProps} />
  </div>
);

export default AppLayout;
