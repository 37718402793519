import { FunctionComponent } from 'react';
import { ReactComponent as Logo } from '../images/brand/Logo_2ColorWhite.svg';

const LoginBrand: FunctionComponent = () => {
  return (
    <div className="bg-gray-800">
      <div className="p-4 text-center">
        <Logo height={45} className="h-12 mx-auto" />
      </div>
    </div>
  );
};

export default LoginBrand;
