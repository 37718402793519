import { FunctionComponent, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router-dom';
import apiService from '../api/apiService';
import AppLayout from '../AppLayout';
import Alert from '../components/Alert';
import Button from '../components/Button';

const EventViewerPage: FunctionComponent = () => {
  const history = useHistory();

  // Grab the event ID from the URL
  const { eventId } = useParams<{ eventId: string }>();

  // Store the event data
  const [eventData, setEventData] = useState<any>({});

  const [error, setError] = useState<string>();

  useEffect(() => {
    setError(undefined);
    apiService.events
      .getEvent(eventId)
      .then(({ data }) => {
        setEventData(data);
      })
      .catch((error) => {
        setError(error.message.toString());
      });
  }, [eventId]);

  const viewPreviousEvent = () => {
    const id = parseInt(eventId, 10);
    if (!isNaN(id)) {
      history.push(`/event/${id - 1}`);
    }
  };

  const viewNextEvent = () => {
    const id = parseInt(eventId, 10);
    if (!isNaN(id)) {
      history.push(`/event/${id + 1}`);
    }
  };

  return (
    <AppLayout>
      <Helmet>
        <title>Event viewer</title>
      </Helmet>
      <div className="max-w-4xl p-4 mt-8 mx-auto text-sm bg-white">
        <div className="flex justify-between mb-4">
          <Button onClick={viewPreviousEvent}>Previous</Button>
          <Button onClick={viewNextEvent}>Next</Button>
        </div>
        {error && <Alert>{error}</Alert>}
        <pre className="text-gray-800 overflow-x-auto">
          {JSON.stringify(eventData, null, 2)}
        </pre>
      </div>
    </AppLayout>
  );
};

export default EventViewerPage;
