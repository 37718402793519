import { useMemo, useState } from 'react';
import _get from 'lodash/get';

export type SortDirection = 'asc' | 'desc';

interface SortConfig {
  direction?: SortDirection;
  sortKey?: string;
}

const useSortedData = <T>(data: T[], config?: SortConfig) => {
  const [sortState, setSortState] = useState(config || {});

  const sortedData = useMemo(() => {
    const sorted = [...data];

    // Return data as is
    if (!sortState.sortKey || !sortState.direction) {
      return sorted;
    }

    sorted.sort((a, b) => {
      const aValue = _get(a, sortState.sortKey!);
      const bValue = _get(b, sortState.sortKey!);
      if (aValue < bValue) {
        return sortState.direction === 'asc' ? -1 : 1;
      } else if (aValue > bValue) {
        return sortState.direction === 'asc' ? 1 : -1;
      }
      return 0;
    });

    return sorted;
  }, [data, sortState]);

  const requestSort = (sortKey: string) => {
    if (sortState.sortKey === sortKey) {
      let updatedSortDirection: SortDirection | undefined = undefined;
      if (sortState.direction === undefined) {
        updatedSortDirection = 'desc';
      } else if (sortState.direction === 'desc') {
        updatedSortDirection = 'asc';
      } else {
        updatedSortDirection = undefined;
      }
      setSortState({ sortKey, direction: updatedSortDirection });
    } else {
      setSortState({ sortKey, direction: 'desc' });
    }
  };

  return { sortedData, requestSort, sortState };
};

export default useSortedData;
