import { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';
import Button from '../../components/Button';
import LoginBrand from '../../components/LoginBrand';
import StarsBackgroundContainer from '../../components/StarsBackgroundContainer';
import useQuery from '../../hooks/useQuery';

const RegisterErrorPage: FunctionComponent = () => {
  const query = useQuery();
  const msg = query.get('msg');

  const goLogin = (event: any) => {
    event.preventDefault();
    window.location.href = '/login';
  };

  return (
    <div className="h-full">
      <Helmet>
        <title>Registration Error</title>
      </Helmet>
      <StarsBackgroundContainer width={400}>
        <LoginBrand />
        <div>
          <div className="p-4">
            <p className="text-lg mb-6 text-gray-700 text-center">
              {msg || 'Something went wrong. Try again?'}
            </p>
            <Button className="w-full mt-4" variant="brand" onClick={goLogin}>
              Got it.
            </Button>
          </div>
        </div>
      </StarsBackgroundContainer>
    </div>
  );
};

export default RegisterErrorPage;
