import { Component, ErrorInfo } from 'react';
import Alert from './Alert';

class ErrorBoundary extends Component {
  state = { hasError: false };

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error(errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <Alert>Something went wrong, please reload the page</Alert>;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
