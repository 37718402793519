import { ResponsiveLine } from '@nivo/line';
import { DateTime } from 'luxon';
import { FunctionComponent, useEffect, useState } from 'react';
import apiService from '../../api/apiService';
import SpinnerSlate from '../SpinnerSlate';
import NivoTooltip from './NivoTooltip';

interface Props {
  uuid: string;
  moduleName: string;
  startAt?: string;
  endAt?: string;
}

const ModuleDetails: FunctionComponent<Props> = ({
  moduleName,
  uuid,
  startAt,
  endAt,
}) => {
  const [graphData, setGraphData] = useState<
    {
      id: string;
      data: Array<{ x: string; y: number }>;
    }[]
  >();
  const [error, setError] = useState<string>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(
    function loadCurrentUser() {
      setIsLoading(true);

      apiService.charts
        .getModuleRecords(uuid, moduleName, startAt, endAt)
        .then(({ data }) => {
          setGraphData([{ id: 'session', data }]);
        })
        .catch((err) => {
          setError(err.toString());
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [uuid, moduleName, startAt, endAt],
  );

  if (isLoading) {
    return <SpinnerSlate style={{ height: 300 }} />;
  }

  return (
    <div>
      {error && (
        <div className="flex items-center justify-center text-gray-700 h-48 bg-gray-50">
          {error}
        </div>
      )}
      {!isLoading && !error && graphData && (
        <div style={{ height: 300 }}>
          <ResponsiveLine
            data={graphData}
            theme={{ fontFamily: 'Inter, Arial, sans-serif' }}
            margin={{ top: 10, right: 30, bottom: 60, left: 60 }}
            curve="monotoneX"
            xScale={{
              type: 'time',
              format: '%Y-%m-%d',
              useUTC: false,
              precision: 'day',
            }}
            xFormat="time:%Y-%m-%d"
            yScale={{
              type: 'linear',
            }}
            axisLeft={{
              legend: 'Sessions',
              legendPosition: 'middle',
              legendOffset: -48,
            }}
            axisBottom={{
              format: '%b %d',
              tickValues: 10,
              legend: 'Time',
              legendPosition: 'middle',
              legendOffset: 40,
            }}
            colors="#3B82F6"
            enablePoints={false}
            enableSlices="x"
            sliceTooltip={({ slice }) => (
              <NivoTooltip>
                <div>
                  {DateTime.fromISO(slice.points[0].data
                    .xFormatted as string).toLocaleString(DateTime.DATE_MED)}
                </div>
                {slice.points.map((point) => (
                  <div
                    key={point.id}
                    style={{
                      color: point.serieColor,
                      padding: '3px 0',
                    }}
                  >
                    <span>{point.data.yFormatted} sessions</span>
                  </div>
                ))}
              </NivoTooltip>
            )}
          />
        </div>
      )}
    </div>
  );
};

export default ModuleDetails;
