import cx from 'classnames';
import { FunctionComponent, SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
  direction?: 'up' | 'right' | 'down' | 'left';
}

const ArrowIcon: FunctionComponent<Props> = ({
  direction = 'down',
  className,
  ...otherProps
}) => {
  const classes = cx(className, 'transform transition-transform', {
    'rotate-90': direction === 'left',
    'rotate-180': direction === 'up',
    '-rotate-90': direction === 'right',
  });

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      className={classes}
      {...otherProps}
    >
      <path
        fillRule="evenodd"
        d="M14.707 12.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l2.293-2.293a1 1 0 011.414 0z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default ArrowIcon;
