import { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';
import { generatePath, Link, Redirect } from 'react-router-dom';
import AppLayout from '../../AppLayout';
import useUserProfile from '../../hooks/useUserProfile';
import { TAppInfo } from '../../types/backend';

interface Props {
  apps: TAppInfo[];
}

const appPath = (uuid: string) => generatePath(`/dashboard/:uuid`, { uuid });

const DashboardIndexPage: FunctionComponent<Props> = () => {
  const { apps, isAuthenticated } = useUserProfile((state) => state);

  // If the user only has access to 1 app, redirect straight to it
  if (apps.length === 1) {
    return <Redirect to={`/dashboard/${apps[0].uuid}`} />;
  }

  return (
    <AppLayout>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      {isAuthenticated && (
        <div className="max-w-5xl px-4 py-8 mx-auto">
          {apps.length > 0 && (
            <>
              {' '}
              <h1 className="text-lg text-gray-800 font-semibold mb-4">
                Available apps:
              </h1>
              <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {apps.map((app) => (
                  <li key={app.id}>
                    <Link
                      to={appPath(app.uuid)}
                      className="block p-4 cursor-pointer bg-white border border-gray-100 rounded hover:border-gray-300 focus:ring"
                    >
                      {app.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </>
          )}
          {apps.length === 0 && (
            <div className="bg-gray-100 flex py-24 px-4 items-center justify-center">
              <p>
                You do not have access to any applications. Please contact your
                access provider or{' '}
                <a href="mailto:support@forwardxp.com">ForwardXP</a>.
              </p>
            </div>
          )}
        </div>
      )}
    </AppLayout>
  );
};

export default DashboardIndexPage;
