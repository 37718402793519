import cx from 'classnames';
import { FunctionComponent, ReactNode } from 'react';
import ArrowIcon from './icons/ArrowIcon';

interface Props {
  isSorted?: boolean;
  label: ReactNode;
  isSortedAsc?: boolean;
  sortKey: string;
  requestSort: (sortKey: string) => void;
}

const SortableHeader: FunctionComponent<Props> = ({
  label,
  sortKey,
  requestSort,
  isSorted,
  isSortedAsc,
}) => {
  return (
    <td
      className={cx({
        'bg-gray-100': isSorted,
        'hover:bg-gray-100': !isSorted,
      })}
    >
      <button
        type="button"
        className="p-2 font-semibold flex items-center w-full"
        onClick={() => requestSort(sortKey)}
      >
        <span className="mr-2">{label}</span>
        <ArrowIcon
          className={cx('w-4 h-4', { 'opacity-0': !isSorted })}
          direction={isSortedAsc ? 'down' : 'up'}
        />
      </button>
    </td>
  );
};

export default SortableHeader;
