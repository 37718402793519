import React from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router-dom';
import Button from '../../components/Button';
import LoginBrand from '../../components/LoginBrand';
import StarsBackgroundContainer from '../../components/StarsBackgroundContainer';

export type TRegisterProps = RouteComponentProps<any, any>;

type TRegisterState = void;

export default class ForgotPasswordTriggeredPage extends React.Component<
  TRegisterProps,
  TRegisterState
> {
  public render(): React.ReactElement<TRegisterProps> {
    if (!this.props.location || !this.props.location.pathname) {
      return <div />;
    }

    return (
      <div className="h-full">
        <Helmet>
          <title>Password Reminder Sent</title>
        </Helmet>
        <StarsBackgroundContainer width={400}>
          <LoginBrand />
          <div>
            <div className="p-4">
              <h1 className="text-xl font-light leading-5 mb-6 text-gray-700 text-center">
                You're Almost Done
              </h1>
              <p className="leading-5 mb-6 text-gray-700 text-center">
                We have sent password reset instructions to the email associated
                with this account, if there is one.
              </p>
              <div className="mt-4">
                <Button
                  className="w-full"
                  variant="brand"
                  onClick={this.goHome}
                >
                  Alright, I'll Check My Email
                </Button>
              </div>
            </div>
          </div>
        </StarsBackgroundContainer>
      </div>
    );
  }

  private goHome = (event: any) => {
    event.preventDefault();
    window.location.href = '/';
  };
}
