import { FormEvent, FunctionComponent, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';
import apiService from '../../api/apiService';
import AppLayout from '../../AppLayout';
import Alert from '../../components/Alert';
import Button from '../../components/Button';
import SelectInput from '../../components/SelectInput';
import { formatAxiosError } from '../../utils/format';
import AdminSideNav from './AdminSideNav';

const UserAdminPage: FunctionComponent = () => {
  // Grab the user ID from the URL
  const { userId } = useParams<{ userId: string }>();

  // Store the user's data
  const [userData, setUserData] = useState<any>();

  // Keep track of whether the UI is loading or saving data.
  const [isLoading, setIsLoading] = useState(false);

  // Show a confirmation message when the form is saved
  const [showConfirmation, setShowConfirmation] = useState(false);

  // Show errors when API calls fail
  const [error, setError] = useState<string>();

  // Load data about the user
  useEffect(() => {
    setIsLoading(true);
    apiService.admin
      .getUser(userId)
      .then(({ data }) => {
        setUserData(data);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(
          formatAxiosError(
            error,
            'Unable to fetch data for this user. Please reload the page.',
          ),
        );

        setIsLoading(false);
      });
  }, [userId]);

  /**
   * Submit the form to update the user's data
   */
  const handleSubmitForm = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // Pick out the fields we want to send to the backend
    const updatedData = {
      name: userData.name,
      role: userData.role,
    };

    // Persist the new data
    setIsLoading(true);
    setError(undefined);

    apiService.admin
      .updateUser(userData.id, updatedData)
      .then(({ data: updatedUser }) => {
        // Sync the UI with the returned data
        setUserData(updatedUser);

        // Show the confirmation message for 3 seconds
        setShowConfirmation(true);
        setTimeout(() => {
          setShowConfirmation(false);
        }, 3000);

        setIsLoading(false);
      })
      .catch(() => {
        setError(
          'Unable to save changes to this user. Please reload the page.',
        );

        setIsLoading(false);
      });
  };

  const updateField = (
    event: FormEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = event.currentTarget;
    setUserData(Object.assign({}, userData, { [name]: value }));
  };

  return (
    <AppLayout>
      <Helmet>
        <title>User admin</title>
      </Helmet>
      <div>
        <div className="flex w-full max-w-5xl mx-auto py-12">
          <div className="w-2/5">
            <AdminSideNav />
          </div>
          <div className="w-4/5 p-4 md:p-6 ml-6 bg-white shadow rounded">
            {error && <Alert>{error}</Alert>}

            <Link to="/admin/users" className="mb-6 block">
              ‹ Back to user list
            </Link>

            {userData != null && (
              <div className="text-gray-800">
                <h1 className="text-3xl mb-8">{userData.email}</h1>

                <form className="w-3/5" onSubmit={handleSubmitForm}>
                  <fieldset className="mb-6">
                    <label className="label " htmlFor="name">
                      Name
                    </label>
                    <input
                      autoComplete="off"
                      required
                      minLength={1}
                      type="text"
                      name="name"
                      style={{ maxWidth: 300 }}
                      id="name"
                      className="textfield"
                      onChange={updateField}
                      value={userData.name}
                    />
                  </fieldset>

                  <fieldset className="mb-6">
                    <label className="label " htmlFor="role">
                      Role
                    </label>
                    <p className="mb-3">
                      The "user" role is the default one and gives granular
                      access to specific apps. The "superadmin" role should only
                      be used for ForwardXP employees: it gives access to all
                      users and apps.
                    </p>
                    <SelectInput
                      autoComplete="off"
                      required
                      name="role"
                      id="role"
                      className="max-w-xs"
                      onChange={updateField}
                      value={userData.role}
                    >
                      <option value="user">User</option>
                      <option value="superadmin">Superadmin</option>
                    </SelectInput>
                  </fieldset>

                  <div className="mt-8 flex items-center">
                    <Button disabled={isLoading} type="submit" variant="brand">
                      Save Changes
                    </Button>
                    {showConfirmation && (
                      <span className="ml-4">Changes saved!</span>
                    )}
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </AppLayout>
  );
};

export default UserAdminPage;
