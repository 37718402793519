import { FunctionComponent, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import apiService from '../../api/apiService';
import AppLayout from '../../AppLayout';
import Alert from '../../components/Alert';
import Button from '../../components/Button';
import AppSettingsLayout from './AppSettingsLayout';

const UserEmailSettingsPage: FunctionComponent = () => {
  const [email, setEmail] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [verificationResent, setVerificationResent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userProfile, setUserProfile] = useState<any>();
  const [error, setError] = useState<string>();

  useEffect(() => {
    setIsLoading(true);

    apiService.admin
      .getUserEmailSettings()
      .then(({ data }) => {
        setUserProfile(data);
      })
      .catch((error) => {
        setError(error.toString());
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const onChangeCurrentPassword = (ev: any) => {
    setCurrentPassword(ev.target.value);
  };

  const onChangeEmail = (ev: any) => {
    setEmail(ev.target.value);
  };

  const onSubmitVerification = () => {
    onSubmitEmail();
  };

  const onResendVerification = () => {
    setIsLoading(true);
    apiService.admin
      .resendEmailChangeVerification()
      .then(() => {
        setVerificationResent(true);
      })
      .catch((error) => {
        setError(error.toString());
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onSubmitEmail = () => {
    if (!currentPassword) {
      setError('Missing current password');
      return;
    }

    setIsLoading(true);

    const saveParams = {
      email,
      oldPassword: currentPassword,
    };

    apiService.admin
      .saveUserEmailSettings(saveParams)
      .then(() => {
        window.location.href = '/verify/email';
      })
      .catch((error) => {
        setError(error.toString());
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <AppLayout>
      <AppSettingsLayout>
        <Helmet>
          <title>Email Settings</title>
        </Helmet>
        {userProfile && (
          <div className="text-gray-800">
            <h1 className="text-3xl mb-8">Change Email Address</h1>
            {error && <Alert>{error}</Alert>}
            <div className="flex">
              <div className="w-3/5">
                <h1 className="text-xl font-light leading-5 mr-6 mb-8">
                  Current: {email}
                </h1>
                <div className="space-y-6">
                  <fieldset>
                    <label className="label" htmlFor="current">
                      Current Password:
                    </label>
                    <input
                      onChange={onChangeCurrentPassword}
                      type="password"
                      id="current"
                      className="textfield"
                      autoComplete="current-password"
                      placeholder=""
                      disabled={isLoading}
                    />
                  </fieldset>

                  <fieldset>
                    {userProfile.pending_email && (
                      <div className="text-lg">
                        <h1 className="text-xl font-light leading-5">
                          Pending Email Change:
                        </h1>
                        <div>
                          <span className="mr-6">
                            {userProfile.pending_email}
                          </span>
                          {!verificationResent && (
                            <Button
                              variant="brand"
                              onClick={onResendVerification}
                            >
                              Resend Verification
                            </Button>
                          )}
                          {verificationResent && <b>Sent!</b>}
                        </div>
                      </div>
                    )}
                    <div>
                      <label className="label" htmlFor="email">
                        New Email Address:
                      </label>
                      <input
                        onChange={onChangeEmail}
                        type="text"
                        id="email"
                        className="textfield"
                        placeholder="Enter new email address"
                        disabled={isLoading}
                      />
                    </div>
                  </fieldset>
                </div>

                <Button
                  loading={isLoading}
                  variant="brand"
                  className="mt-6"
                  onClick={onSubmitVerification}
                >
                  Change Email
                </Button>
              </div>
            </div>
          </div>
        )}
      </AppSettingsLayout>
    </AppLayout>
  );
};

export default UserEmailSettingsPage;
