import { AnchorHTMLAttributes, FunctionComponent, MouseEvent } from 'react';
import ReactGA from 'react-ga';

const ExternalLink: FunctionComponent<
  AnchorHTMLAttributes<HTMLAnchorElement>
> = ({ children, onClick, ...otherProps }) => {
  const trackClick = (event: MouseEvent<HTMLAnchorElement>) => {
    ReactGA.event({
      category: 'outbound',
      action: 'click',
      label: otherProps.href,
      transport: 'beacon',
    });

    if (onClick) {
      onClick(event);
    }
  };

  return (
    <a
      onClick={trackClick}
      target="_blank"
      rel="noopener noreferrer"
      {...otherProps}
    >
      {children}
    </a>
  );
};

export default ExternalLink;
