import { FunctionComponent, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { generatePath, Link } from 'react-router-dom';
import apiService from '../../api/apiService';
import AppLayout from '../../AppLayout';
import Alert from '../../components/Alert';
import { formatAxiosError } from '../../utils/format';

const ManageUserPage: FunctionComponent = () => {
  const [users, setUsers] = useState<any[]>([]);
  const [error, setError] = useState<string>();

  useEffect(() => {
    apiService.admin
      .getAllUsers()
      .then(({ data }) => {
        setUsers(data);
      })
      .catch((error) => {
        setError(
          formatAxiosError(
            error,
            'Unable to fetch the list of users. Please refresh the page.',
          ),
        );
      });
  }, []);

  return (
    <AppLayout>
      <Helmet>
        <title>User admin</title>
      </Helmet>
      <div className="bg-white p-4 max-w-7xl mx-auto mt-6">
        <h1 className="text-2xl mb-6">Manage users</h1>

        {error && <Alert>{error}</Alert>}

        <table className="w-full whitespace-nowrap">
          <thead className="text-left bg-gray-50 border border-gray-200 text-gray-500 text-sm uppercase">
            <tr>
              <th className="p-2 font-semibold pl-6 w-0">ID</th>
              <th className="p-2 font-semibold">Email</th>
              <th className="p-2 font-semibold">Role</th>
              <th className="p-2 font-semibold">Status</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr
                key={user.id}
                className="text-left border border-gray-200 hover:bg-gray-50"
              >
                <td className="p-2 pl-6 text-gray-500 text-sm font-mono">
                  {user.id}
                </td>
                <td className="p-2">
                  <div>
                    <Link
                      className="text-indigo-600 hover:text-indigo-700 font-semibold"
                      to={generatePath('/admin/users/:userId', {
                        userId: user.id,
                      })}
                    >
                      {user.email}
                    </Link>
                  </div>
                  <div>{user.name}</div>
                </td>
                <td className="p-2 text-gray-600">{user.role}</td>
                <td className="p-2 text-gray-600">{user.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </AppLayout>
  );
};

export default ManageUserPage;
