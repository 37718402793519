import { FormEvent, FunctionComponent, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useHistory } from 'react-router-dom';
import apiService from '../api/apiService';
import Button from '../components/Button';
import LoginBrand from '../components/LoginBrand';
import StarsBackgroundContainer from '../components/StarsBackgroundContainer';

const ForgotPasswordPageBase: FunctionComponent = () => {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [error, setError] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  const onEmailChanged = (event: any): void => {
    setEmail(event.target.value);
  };

  const submitForm = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setError(undefined);

    const [validationError, validatedParams] = validateForgotParams({ email });
    if (validationError) {
      setError(`Validation error: ${validationError}`);
      return;
    }

    if (!validatedParams || !validatedParams.email) {
      setError(
        `Please enter your email address then hit "Forgot Your Password?"`,
      );
      return;
    }

    setIsLoading(true);

    apiService
      .sendPasswordResetEmail(validatedParams.email)
      .then(() => {
        if (typeof window.ga === 'function') {
          window.ga('send', 'event', 'start_reset_password');
        }

        history.push('/verify/reset');
      })
      .catch((err) => {
        setError(err.toString());
        console.error('Failed to reset forgot password', err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    // Reset the email when navigating away
    return () => {
      setEmail('');
    };
  }, []);

  return (
    <div className="h-full">
      <Helmet>
        <title>Forgot Password</title>
      </Helmet>
      <StarsBackgroundContainer width={400}>
        <LoginBrand />
        <div>
          <div className="p-4 relative text-gray-800">
            <h1 className="text-xl font-light leading-5 mb-6 text-center">
              Forgot your Password?
            </h1>
            {error && (
              <div className="bg-red-100 text-red-700 p-4">{error}</div>
            )}
            <div className="rio-form">
              <form onSubmit={submitForm}>
                <div className="mb-6">
                  <label className="label" htmlFor="email" tabIndex={1}>
                    Email
                  </label>
                  <input
                    type="text"
                    className="textfield"
                    id="email"
                    autoComplete="email"
                    onChange={onEmailChanged}
                    autoFocus
                  />
                </div>

                <Button
                  loading={isLoading}
                  large
                  className="w-full"
                  variant="brand"
                  type="submit"
                >
                  Reset password
                </Button>

                <div className="mt-6 text-lg font-light leading-5">
                  Need to <Link to="/login">log in?</Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </StarsBackgroundContainer>
    </div>
  );
};

function cleanupString(str: string) {
  if (typeof str !== 'string') {
    return '';
  }
  return str.trim();
}

function validateForgotParams(params: {
  email: string;
}): [string | null, { email: string }?] {
  const newParams = {
    email: cleanupString(params.email),
  };

  const missingFields = [];

  if (!newParams.email) {
    missingFields.push('Email');
  }

  if (missingFields.length > 0) {
    return [`Missing value for ${missingFields.join(', ')}`];
  }

  return [null, newParams];
}

export default ForgotPasswordPageBase;
