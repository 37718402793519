import React, { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';
import Button from '../../components/Button';
import LoginBrand from '../../components/LoginBrand';
import StarsBackgroundContainer from '../../components/StarsBackgroundContainer';

const RegisterSuccessPage: FunctionComponent = () => {
  const goHome = (event: any) => {
    event.preventDefault();
    window.location.href = '/';
  };

  return (
    <div className="h-full">
      <Helmet>
        <title>Registration Verification Sent</title>
      </Helmet>
      <StarsBackgroundContainer width={400}>
        <LoginBrand />
        <div>
          <div className="p-4">
            <h1 className="text-xl leading-5 mb-6 text-gray-700 text-center">
              Just One More Thing...
            </h1>
            <p className="mb-6 text-gray-700 text-center">
              Please go <b>check your email</b> to validate your account, then
              you'll be all set to go!
            </p>
            <Button className="w-full mt-4" variant="brand" onClick={goHome}>
              Alright, I'll Check My Email
            </Button>
          </div>
        </div>
      </StarsBackgroundContainer>
    </div>
  );
};

export default RegisterSuccessPage;
