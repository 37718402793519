import cx from 'classnames';
import { Component } from 'react';
import { Link } from 'react-router-dom';

const GENERAL_SETTINGS = [
  ['Date & Time', '/settings/time'],
  ['Change Email Address', '/settings/email'],
  ['Change Password', '/settings/password'],
  // ['Change Username', '/settings/username'],
  // ['Delete Account', '/settings/delete-account']
];

class UserSettingsNav extends Component<any, any> {
  public render(): React.ReactElement<any> {
    return (
      <div className="bg-white rounded-lg p-2 shadow">
        <h2 className="px-2 py-4 font-semibold text-black text-lg">
          Account Settings
        </h2>
        <ul className="space-y-1">
          {GENERAL_SETTINGS.map(([title, page]) => (
            <li key={title}>
              <Link
                to={page}
                className={cx(
                  'block p-2 rounded text-gray-700 hover:bg-gray-100 hover:text-gray-800',
                  {
                    'font-semibold bg-gray-200':
                      this.props.location.pathname === page,
                  },
                )}
              >
                {title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default UserSettingsNav;
