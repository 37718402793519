import cx from 'classnames';
import { ButtonHTMLAttributes, FunctionComponent } from 'react';
import Spinner from './Spinner';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'brand' | 'neutral' | 'destructive';
  large?: boolean;
  loading?: boolean;
}

const Button: FunctionComponent<Props> = ({
  variant = 'neutral',
  large = false,
  children,
  className,
  loading,
  disabled,
  ...otherProps
}) => {
  const classes = cx(
    className,
    'flex items-center justify-center border rounded py-2 px-3 font-semibold transition-colors hover:shadow-sm',
    {
      'text-lg': large,
      'text-md': !large,
      'bg-white border-gray-300 text-gray-700 hover:border-gray-400 hover:text-gray-900':
        variant === 'neutral',
      'bg-indigo-700 border-indigo-800 text-white hover:bg-indigo-800 hover:text-indigo-50':
        variant === 'brand',
      'bg-white border-red-200 text-red-700 hover:text-red-800 hover:bg-red-50':
        variant === 'destructive',
      'opacity-75': disabled || loading,
    },
  );

  return (
    <button {...otherProps} disabled={loading || disabled} className={classes}>
      {children} {loading && <Spinner className="ml-4" />}
    </button>
  );
};

export default Button;
