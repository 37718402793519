import { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';

const DocumentMeta: FunctionComponent = () => {
  return (
    <Helmet
      titleTemplate="STARS: %s | ForwardXP"
      defaultTitle="Stats Tracking And Reporting System"
    >
      <meta property="og:url" content="https://stars.forwardxp.com" />
      <meta
        property="og:image"
        content="http://cdn.forwardxp.com/images/fb_app_image.jpg?2019-11-22"
      />
      <meta
        property="og:title"
        content="STARS: Stats Tracking And Reporting System"
      />
      <meta property="og:type" content="website" />
      <meta
        name="description"
        content="ForwardXP Stats Tracking And Reporting System"
      />
      <meta
        property="og:description"
        content="ForwardXP Stats Tracking And Reporting System"
      />
      <meta
        property="twitter:image"
        content="http://cdn.forwardxp.com/images/fb_app_image.jpg?2019-11-22"
      />
      <meta property="twitter:card" content="summary" />
      <meta property="theme-color" content="#ffbd0a" />
    </Helmet>
  );
};

export default DocumentMeta;
