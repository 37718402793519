import Cookies from 'js-cookie';
import { FunctionComponent, useEffect } from 'react';
import apiService from '../api/apiService';
import { CLIENT_LOGGED_IN_COOKIE } from '../components/PrivateRoute';

const LogoutPage: FunctionComponent = () => {
  useEffect(() => {
    // Clear the client-side cookie
    Cookies.remove(CLIENT_LOGGED_IN_COOKIE);

    // Log out on the server side
    apiService.logOut().then(() => {
      window.location.href = '/login';
    });
  }, []);

  return <div>Logging out...</div>;
};

export default LogoutPage;
